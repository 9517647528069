const getDefaultState = () => {
  return {
    projects: []
  }
};

export default {
  state: getDefaultState(),
  actions: {
    projectsResetAll({commit}) {
      commit("projectsResetAll");
    },
  },
  mutations: {
    projectAdd(state, project) {
      state.projects.push(project);
    },
    projectDelete(state, projectId) {
      let index = state.projects.findIndex(item => item.id === projectId );
      state.projects.splice(index, 1);
    },
    projectsAdd(state, projects) {
      state.projects.splice(0, state.projects.length);
      projects.forEach(function(project) {
        state.projects.push(project);
      });
    },
    projectUpdateRef(state, data) {
      let index = state.projects.findIndex(item => item.id === data.id );
      state.projects[index].refID = data.refID;
    },
    projectEditName(state, project) {
      let index = state.projects.findIndex(item => item.id === project.id );
      state.projects[index].name = project.name;
    },
    projectEditColor(state, project) {
      let index = state.projects.findIndex(item => item.id === project.id );
      state.projects[index].color = project.color;
    },
    projectsResetAll(state) {
      state.projects.splice(0, state.projects.length);
    },
  }
}
