const getDefaultState = () => {
  return {
    boards: [],
    blocks: [],
    blocksTemplates: []
  }
};

export default {
  state: getDefaultState(),
  actions: {
    resetEverything({commit}) {
      commit("resetAll");
      commit("resetTemplates");
    },
  },
  mutations: {
    blockIncrement(state, blockId) {
      let index = state.blocks.findIndex(item => item.id === blockId );
      const now = new Date().getTime();
      let activeSince = state.blocks[index].activeSince;
      if (activeSince === 0) activeSince = now;
      state.blocks[index].elapsedTime = state.blocks[index].totalTime + (now - activeSince);
    },
    blockReset(state, blockId) {
      let index = state.blocks.findIndex(item => item.id === blockId );
      state.blocks[index].elapsedTime = 0;
      state.blocks[index].totalTime = 0;
      state.blocks[index].activeSince = 0;
      if (state.blocks[index].elements.length) {
        state.blocks[index].elements.splice(0, state.blocks[index].elements.length);
      }
    },
    blockAdd(state, block) {
      state.blocks.push(block);
    },
    blockDelete(state, blockId) {
      let index = state.blocks.findIndex(item => item.id === blockId );
      state.blocks.splice(index, 1);
    },
    blockAdded(state, blockId) {
      let index = state.blocks.findIndex(item => item.id === blockId );
      delete state.blocks[index].isNew;
    },
    blocksAdd(state, blocks) {
      state.blocks.splice(0, state.blocks.length);
      blocks.forEach(function(block) {
        // check all blocks with elements
        // update elapsed time if not active
        if (block.activeSince === 0) {
          block.elapsedTime = block.totalTime;
        }
        state.blocks.push(block);
      });
    },
    blockUpdateRef(state, data) {
      let index = state.blocks.findIndex(item => item.id === data.id );
      state.blocks[index].refID = data.refID;
    },
    blockEditTitle(state, block) {
      let index = state.blocks.findIndex(item => item.id === block.id );
      state.blocks[index].title = block.title;
    },
    blockEditSize(state, block) {
      let index = state.blocks.findIndex(item => item.id === block.id );
      state.blocks[index].size = block.size;
    },
    blockEditIncreaseValue(state, block) {
      let index = state.blocks.findIndex(item => item.id === block.id );
      state.blocks[index].increaseValue = block.increaseValue;
    },
    blockEditColor(state, block) {
      let index = state.blocks.findIndex(item => item.id === block.id );
      state.blocks[index].color = block.color;
    },
    blockEditProjectRef(state, block) {
      let index = state.blocks.findIndex(item => item.id === block.id );
      state.blocks[index].projectRef = block.projectRef;
    },
    blockEditSkin(state, block) {
      let index = state.blocks.findIndex(item => item.id === block.id );
      state.blocks[index].skin = block.skin;
    },
    blockEditFaceType(state, block) {
      let index = state.blocks.findIndex(item => item.id === block.id );
      state.blocks[index].faceType = block.faceType;
    },
    blockEditElements(state, block) {
      let index = state.blocks.findIndex(item => item.id === block.id );
      state.blocks[index].elements = block.elements;
    },
    blockEditCollectors(state, block) {
      let index = state.blocks.findIndex(item => item.id === block.id );
      state.blocks[index].collectors = block.collectors;
    },
    blockEditTotalTime(state, block) {
      let index = state.blocks.findIndex(item => item.id === block.id );
      state.blocks[index].totalTime = block.totalTime;
      state.blocks[index].elapsedTime = block.totalTime;
    },
    blockEditProgressStart(state, block) {
      let index = state.blocks.findIndex(item => item.id === block.id );
      state.blocks[index].progressStart = block.progressStart;
    },
    blockEditProgressEnd(state, block) {
      let index = state.blocks.findIndex(item => item.id === block.id );
      state.blocks[index].progressEnd = block.progressEnd;
    },
    blockEditCounters(state, block) {
      let index = state.blocks.findIndex(item => item.id === block.id );
      state.blocks[index].counters = block.counters;
    },
    blockEditEmbedId(state, block) {
      let index = state.blocks.findIndex(item => item.id === block.id );
      state.blocks[index].embedId = block.embedId;
    },
    blockEditEmbedOptions(state, block) {
      let index = state.blocks.findIndex(item => item.id === block.id );
      state.blocks[index].embedOptions = block.embedOptions;
    },
    blockAddTime(state, block) {
      let index = state.blocks.findIndex(item => item.id === block.id );
      state.blocks[index].elapsedTime += block.time;
    },
    blockPin(state, block) {
      let index = state.blocks.findIndex(item => item.id === block.id );
      state.blocks[index].pinned = !state.blocks[index].pinned;
    },
    blockNotificationUpdate(state, block) {
      let index = state.blocks.findIndex(item => item.id === block.id );
      state.blocks[index].notificationSent = block.status;
    },
    blockRemoveTime(state, block) {
      let index = state.blocks.findIndex(item => item.id === block.id );
      state.blocks[index].elapsedTime -= block.time;
    },
    elementStart(state, data) {
      state.blocks[data.index].elements.push(data.element);
      state.blocks[data.index].activeSince = data.element.start;
    },
    elementEnd(state, data) {
      state.blocks[data.index].elements[data.last].end = data.end;
      const total = state.blocks[data.index].elements[data.last].end - state.blocks[data.index].elements[data.last].start;
      state.blocks[data.index].totalTime += total;
      state.blocks[data.index].activeSince = 0;
    },
    elementUpdate(state, data) {
      state.blocks[data.index].elements.push(data.element);
    },
    elementRemove(state, data) {
      state.blocks[data.index].elements.splice(state.blocks[data.index].elements.length - 1, 1);
    },
    countdownIncrement(state, blockId) {
      let index = state.blocks.findIndex(item => item.id === blockId );
      const now = new Date().getTime();
      let activeCountdownSince = state.blocks[index].activeCountdownSince;
      if (activeCountdownSince === 0) activeCountdownSince = now;
      state.blocks[index].elapsedTime = now - activeCountdownSince;
    },
    countdownStart(state, data) {
      state.blocks[data.index].activeCountdownSince = data.start;
    },
    countdownEnd(state, data) {
      state.blocks[data.index].activeCountdownSince = 0;
      state.blocks[data.index].elapsedTime = 0;
    },
    resetAll(state) {
      state.boards.splice(0, state.boards.length);
      state.blocks.splice(0, state.blocks.length);
    },
    resetTemplates(state) {
      state.blocksTemplates.splice(0, state.blocksTemplates.length);
    },
    blockSaveTemplate(state, blockTemplate) {
      state.blocksTemplates.push(blockTemplate);
    },
    blockTemplatesAdd(state, blockTemplates) {
      state.blocksTemplates.splice(0, state.blocksTemplates.length);
      blockTemplates.forEach(function(block) {
        // check all blocks with elements
        state.blocksTemplates.push(block);
      });
    },
    blockTemplateDelete(state, blockTemplateId) {
      let index = state.blocksTemplates.findIndex(item => item.id === blockTemplateId );
      state.blocksTemplates.splice(index, 1);
    },
    blockUpdateTemplateRef(state, data) {
      let index = state.blocksTemplates.findIndex(item => item.id === data.id );
      state.blocksTemplates[index].refID = data.refID;
    },
    boardAdd(state, board) {
      state.boards.splice(0, state.boards.length);
      state.boards.push(board);
    },
    boardAddRow(state, row) {
      state.boards[0].rows.push(row);
    },
    boardRemoveRow(state, rowIndex) {
      state.boards[0].rows.splice(rowIndex, 1);
    },
    boardAddColumn(state, data) {
      state.boards[0].rows[data.row].cols.push(data.column);
    },
    boardRemoveColumn(state, data) {
      let index = state.boards[0].rows[data.row].cols.findIndex(item => item.id === data.columnId );
      state.boards[0].rows[data.row].cols.splice(index, 1);
    },
    boardUpdate(state, board) {
      state.boards.splice(0, state.boards.length);
      state.boards.push(board);
    },
    blockAddBoard(state, data) {
      state.boards[0].rows[0].cols[data.colIndex].data.push({
        id: data.id,
        block: data.blockId
      });
    },
    blockDeleteBoard(state, data) {
      state.boards[0].rows[data.rowIndex].cols[data.colIndex].data.splice(data.blockIndex, 1);
    },
    boardUpdateRef(state, data) {
      state.boards[0].refID = data.refID;
    },
  }
}
