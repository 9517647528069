import { createRouter, createWebHistory } from 'vue-router'
import Tracker from '../views/Tracker.vue'
import store from "../store/store";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
    meta: {
      title: 'Home'
    },
  },
  {
    path: '/reports',
    name: 'Reports',
    component: () => import(/* webpackChunkName: "reports" */ '../views/Reports3.vue'),
    meta: {
      title: 'Reports',
      planRequired: true
    },
  },
  {
    path: '/notion-blocks',
    name: 'Notion blocks',
    component: () => import(/* webpackChunkName: "notionBlocks" */ '../views/Notion.vue'),
    meta: {
      title: 'Notion blocks for time, habit and goal tracking'
    },
  },
  {
    path: '/projects',
    name: 'Projects',
    component: () => import(/* webpackChunkName: "projects" */ '../views/Projects.vue'),
    meta: {
      title: 'Projects'
    },
  },
  {
    path: '/changelog',
    name: 'Changelog',
    component: () => import(/* webpackChunkName: "changelog" */ '../views/Changelog.vue'),
    meta: {
      title: 'Changelog'
    },
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: () => import(/* webpackChunkName: "pricing" */ '../views/Pricing.vue'),
    meta: {
      title: 'Pricing'
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      title: 'Login to continue'
    },
  },
  {
    path: '/signup',
    name: 'Sign up',
    component: () => import(/* webpackChunkName: "signup" */ '../views/SignUp.vue'),
    meta: {
      title: 'Sign up'
    },
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      title: 'About'
    },
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "privacypolicy" */ '../views/PrivacyPolicy.vue'),
    meta: {
      title: 'Privacy Policy'
    },
  },
  {
    path: '/terms',
    name: 'Terms of Service',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue'),
    meta: {
      title: 'Terms'
    },
  },
  {
    path: '/acceptable-use-policy',
    name: 'Acceptable Use Policy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "acceptableusepolicy" */ '../views/AcceptableUsePolicy.vue'),
    meta: {
      title: 'Acceptable Use Policy'
    },
  },
  {
    path: '/tracker',
    name: 'Tracker',
    component: Tracker,
    meta: {
      title: 'Tracker',
      planRequired: true
    },
  },
  {
    path: "/recover",
    name: "Recover password",
    component: () => import(/* webpackChunkName: "recover" */ '../views/RecoverAccount.vue'),
    meta: {
      title: 'Recover password'
    }
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
    meta: {
      title: 'Profile'
    }
  },
  {
    path: "/subscription",
    name: "Subscription",
    component: () => import(/* webpackChunkName: "subscription" */ '../views/Subscription.vue'),
    meta: {
      title: 'Subscription'
    }
  },
  {
    path: "/profile-edit",
    name: "Profile edit",
    component: () => import(/* webpackChunkName: "profileEdit" */ '../views/ProfileEdit.vue'),
    meta: {
      title: 'Profile edit'
    }
  },
  {
    path: "/status/:id",
    name: "Status",
    component: () => import(/* webpackChunkName: "status" */ '../views/Status.vue'),
    meta: {
      title: 'Status'
    }
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import(/* webpackChunkName: "articles" */ '../views/Articles.vue'),
    meta: {
      title: 'Blog'
    },
  },
  {
    path: '/how-to-be-more-productive-working-from-home',
    name: 'Blog-one',
    component: () => import(/* webpackChunkName: "blogOne" */ '../blog/how-to-be-more-productive-working-from-home.vue'),
    meta: {
      title: 'How to Be More Productive Working From Home'
    },
  },
  {
    path: '/:pathMatch(.*)',
    component: () => import(/* webpackChunkName: "notFound" */ '../views/NotFound.vue'),
    meta: {
      title: '404'
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: 'bg-gray-100 text-white',
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }
  }
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  if (typeof window.InlineManual !== 'undefined') {
    window.InlineManual('update');
  }

  const planRequired = to.matched.some(route => route.meta.planRequired);
  // If the route doesnt have a `meta.planRequired` property go on ahead!
  if (!planRequired) {
    return next();
  }
  // If we go this far then it must have the `meta.authRequired`. But is there a user logged in? If so, then go right on ahead!
  if (store.getters["auth/loggedIn"]) {
    const currentUser = store.getters["auth/currentUser"];
    // User is on free, show them Subscription page :)
    if (currentUser.app_metadata && currentUser.app_metadata.roles.length) {
      if (currentUser.app_metadata.roles[0] === 'free') {
        return next({ name: "Subscription", query: { redirectFrom: to.fullPath } });
      }

      if (currentUser.lock) {
        return next({ name: "Subscription", query: { redirectFrom: to.fullPath } });
      }
    }
  }
  return next();
});


router.afterEach((to) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title + ' | Kairo';
  }
});

export default router
