import faunadb from "faunadb";
import store from "../store/store";

/* configure faunaDB Client with our secret */
export const q = faunadb.query;
// client needs to be mutable so it can dynamically re-initialise when a new user logs in.

const currentUser = store.getters["auth/currentUser"];
let secret = null;
if (currentUser) {
  secret = currentUser.app_metadata.db_token;
}

export let client = new faunadb.Client({
  secret: secret
});

// Its important we watch the current user object and update the fauna db client
// otherwise when switching accounts the previous users token is still cached in memory
// https://github.com/chiubaca/vue-netlify-fauna-starter-kit/issues/3
store.subscribe(mutation => {
  if (mutation.type === "auth/SET_CURRENT_USER") {
    let secret = null
    if (mutation.payload !== null) {
      secret = mutation.payload.app_metadata.db_token;
    }
    client = new faunadb.Client({
      secret: secret
    });
  }
});
