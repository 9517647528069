import { q, client } from "../helpers/init-db";

/**
 *
 * @param {object} journalData - object containing the title of journal, could contain other data too in the future
 */
export function createReport(reportData) {
  const me = q.CurrentIdentity();
  return client
      .query(
          q.Create(q.Collection("reports"), {
            data: {
              ...reportData
            },
            permissions: {
              read: me,
              write: me
            }
          })
      )
      .then(resp => resp);
}



export function getReports() {
  return client
      .query(
          /*
          q.Map(q.Paginate(q.Match(q.Ref("indexes/all_reports"))), ref =>
              q.Get(ref)
          )
          */
          q.Map(
              q.Paginate(
                  q.Match(q.Index("all_reports_by_ts")),

                  {size:1000}
              ),
              q.Lambda(
                  ["ts", "ref"],       // we now have two parameters
                  q.Get(q.Var("ref"))  // and only use the ref, but the result will be automatically sorted by ts
              )
          )
      )
      .then(resp => resp);
}

export function getReportsRange(from, to) {
  return client
      .query(
        q.Map(
          q.Paginate(
            q.Intersection(
              q.Join(
                q.Range(
                    q.Match(q.Index("reports_sortedBy_from")),
                    [from],
                    [to]
                ), q.Lambda(["from", "ref"], q.Match("reports_by_ref", q.Var("ref")))
              ),
              q.Join(
                q.Range(
                  q.Match(q.Index("reports_sortedBy_to")),
                    [from],
                    [to]
                ),
                q.Lambda(["from", "ref"], q.Match("reports_by_ref", q.Var("ref")))
              )
            )
          ),
          q.Lambda(
            ["ref"],
            q.Get(q.Var("ref"))
          )
        )
      )
      .then(resp => resp);
}

/**
 *
 * @param {object} refID - fauna ref object
 */
export function deleteReport(refID) {
  return client
      .query(q.Delete(q.Ref(q.Collection('reports'), refID)))
      .then(resp => resp);
}

/**
 *
 * @param {object} blockRefID - faunaDb journal collection reference ID
 * @param {object} blockData - object of data to update
 */
export function updateReport(refID, reportData) {
  return client
      .query(
          q.Update(q.Ref(q.Collection("reports"), refID), {
            data: {
              ...reportData
            }
          })
      )
      .then(resp => resp);
}
