/**
 * This module is responsible for all state and actions related to the current authenticated user.
 */
import GoTrue from "gotrue-js";
import { useToast } from 'vue-toastification'
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

const toast = useToast();

export default {
  strict: false,
  namespaced: true,

  state() {
    return {
      currentUser: null,
      GoTrueAuth: null,
      authToken: null
    };
  },
  getters: {
    loggedIn: state => !!state.currentUser,
    currentUser: state => state.currentUser,
    netlifyUserLoggedIn: state => !!state.GoTrueAuth.currentUser(),
    currentNetlifyUser: state => state.GoTrueAuth.currentUser(),
    GoTrueAuth: state => state.GoTrueAuth,
    authToken: state => state.tokenActive
  },
  mutations: {
    SET_GOTRUE(state, value) {
      state.GoTrueAuth = value;
    },
    SET_TOKEN(state, value) {
      state.tokenActive = value;
    },
    SET_CURRENT_USER(state, value) {
      // Do we have logged in user?
      if (value !== null) {
        if (value && value.app_metadata && value.app_metadata.roles.length) {
          let plan = value.app_metadata.roles[0].split("_");
          value.app_plan = plan[0];
          value.app_plan_duration = plan[1];
        } else {
          value.app_plan = 'none';
          value.app_plan_duration = null;
        }

        value.lock = false;
        value.status = value.app_metadata.status;

        if (value.app_metadata.status === 'trialing') {
          // compare if trial didn't expire already, trial expired is handled within
          if (dayjs().unix() >= value.app_metadata.trial_end) {
            value.status = 'trial_expired';
            value.lock = true;
          } else {
            let trial_end = dayjs.unix(value.app_metadata.trial_end);
            value.trial_end = "Trial expires " + dayjs().to(trial_end);
          }
        } else if (value.status === 'active' && value.app_metadata.end_at) {
          let cancel_at = dayjs.unix(value.app_metadata.end_at);
          value.cancel_at = "Your plan will be cancelled on " + dayjs(cancel_at).format('DD MMM YYYY');
        } else if (value.status === 'canceled' && value.app_metadata.end_at) {
          let cancel_at = dayjs.unix(value.app_metadata.end_at).add(21, 'days');
          value.cancel_at = "Your account will be deleted " + dayjs().to(cancel_at);
          value.lock = true;
        } else if (value.status === 'active') {
          // active state - any
        } else {
          // unknown status?
          if (typeof value.status === 'undefined' ) {
            // for those who are on beta or so, no status
            value.status = 'custom';
            value.lock = false;
          } else {
            value.status = 'unknown';
            value.lock = true;
          }
        }
      }
      state.currentUser = value;
    }
  },
  actions: {
    /**
     * Calls external signup endpoint for new users logging in via external provider
     * @param {*} store - vuex store object
     * @param {string} JWT - Json web token used for authorisation header
     * @return {promise <object>} -
     */
    invokeSignupFunction(store, JWT) {
      return new Promise((resolve, reject) => {
        let URL = `https://${document.location.hostname}/.netlify/functions/identity-external-signup`;
        console.log("invoking external signup function @", URL);
        // Must provide the user JWT here otherwise we cant update the Netlify user
        // app_metadata properties which is required for storing the users DB token
        fetch(URL, {
          method: "POST",
          headers: {
            "cache-control": "no-cache",
            Authorization: "Bearer " + JWT
          }
        })
            .then(resp => resp.json())
            .then(data => {
              if (data.code >= 400) {
                reject(data.msg);
                console.error(
                    "There was an error invoking external signup",
                    data
                );
              }
              resolve(data);
            })
            .catch(error => {
              reject("error invoking signup function directly", error);
            });
      });
    },

    /**
     * Authorise and login users via email
     * @param {*} store - vuex store object
     * @param {object} credentials - object containing email and password
     * @property {string} credentials.email - email of the user eg hello@email.com
     * @property {string} credentials.password - password string
     */
    attemptLogin({ commit, state }, credentials) {
      return new Promise((resolve, reject) => {
        state.GoTrueAuth.login(credentials.email, credentials.password, true)
            .then(response => {
              commit("SET_CURRENT_USER", response);
              toast.success("Logged in.");
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
      });
    },

    /**
     * Authorise and login user via an external provider. Calling this will open external provider login which will
     * redirect back to the app with JWT in the URL which needs to be decoded.
     * @param {*} store - vuex store object
     * @param {string} provider - eg "Google", "GitHub", "GitLab"
     */
    attemptExternalLogin({ state }, provider) {
      window.location.href = state.GoTrueAuth.loginExternalUrl(provider);
    },

    /**
     *
     * @param {*} store - vuex store object
     * @param {object} params - object containing JWT and other meta data to create/login a user via external provider
     * @property {string} params.access_token - JWT
     * @property {number} params.expires_at eg. 1577573493000
     * @property {string} params.expires_in eg. "3600"
     * @property {string} params.refresh_token eg. "UXarIArZKDt3j-5KyltLJw"
     * @property {string} params.token_type: eg. "bearer"
     */

    completeExternalLogin({ commit, dispatch, state }, params) {
      // This currently getting called in src\helpers\authorise-tokens.js
      return new Promise((resolve, reject) => {
        // If a user already exists, this will return the existing user and not create a new one
        state.GoTrueAuth.createUser(params)
            .then(user => {
              console.log("Completed external login for user ID ", user.id);
              //If db token is present here, theres no need to call the external signup so exit early
              if (user.app_metadata.db_token) {
                console.log(
                    "A db token has already been initialised for this userID ",
                    user.id
                );
                commit("SET_CURRENT_USER", user);
                resolve("Signed in successfully");
                return;
              }

              dispatch("invokeSignupFunction", params.access_token)
                  .then(resp => {
                    commit("SET_CURRENT_USER", resp);
                    resolve("Signed in successfully");
                  })
                  .catch(error => {
                    reject(error);
                  });
            })
            .catch(error => {
              reject(error);
            });
      });
    },

    /**
     *
     * @param {*} store - vuex store object
     * @param {object} credentials - object containing email and password
     * @property {string} credentials.email - email of the user eg hello@email.com
     * @property {string} credentials.password - password string
     */
    attemptSignup({ state }, credentials) {
      console.log(`Attempting signup for ${credentials.email}...`, credentials);
      return new Promise((resolve, reject) => {
        state.GoTrueAuth.signup(credentials.email, credentials.password, {
          full_name: credentials.name
        })
            .then(response => {
              console.log(`Confirmation email sent`, response);
              resolve(response);
            })
            .catch(error => {
              console.log("An error occurred trying to signup", error);
              reject(error);
            });
      });
    },

    /**
     * This confirms a new user from an email signup by parsing the token which has been extracted from the Netlify
     * confirmation email.
     * @param {*} store - vuex store object
     * @param {string} token - token from confimration email eg. "BFX7olHxIwThlfjLGGfaCA"
     */
    attemptConfirmation({ commit, state }, token) {
      console.log("Attempting to verify token", token);
      commit("SET_TOKEN", true);
      return new Promise((resolve, reject) => {
        state.GoTrueAuth.confirm(token)
            .then(response => {
              console.log("User has been confirmed");
              commit("SET_TOKEN", false);
              resolve(response);
            })
            .catch(error => {
              console.log("An error occurred trying to confirm the user", error);
              commit("SET_TOKEN", false);
              reject(error);
            });
      });
    },

    /**
     * Sign out the current user if they are logged in.
     * @param {*} store - vuex store object
     */
    attemptLogout({ state, commit }) {
      return new Promise((resolve, reject) => {
        if (state.GoTrueAuth.currentUser()) {
        state.GoTrueAuth.currentUser()
            .logout()
            .then(resp => {
              commit("SET_CURRENT_USER", null);
              resolve(resp);
            })
            .catch(error => {
              console.error("Could not log user out", error);
              commit("SET_CURRENT_USER", null);
              reject(error);
            });

        } else {
          commit("SET_CURRENT_USER", null);
          Promise.resolve();
        }
      });
    },

    /**
     *not required, delete at some point
     * @param {*} store - vuex store object
     */
    getUserJWTToken({ getters, state }) {
      console.log(getters.currentNetlifyUser);
      if (!getters.currentNetlifyUser) {
        alert("Please sign in again");
        console.warn("User needs to sign in again");
        return;
      }
      state.GoTrueAuth.currentUser()
          .jwt()
          .then(token => {
            alert("got user token: ", token);
          });
    },

    attemptRefresh({ state, commit }) {
      //TODO : fix bug in this action - https://github.com/chiubaca/vue-netlify-fauna-starter-kit/issues/12
      return new Promise((resolve, reject) => {
        const user = state.GoTrueAuth.currentUser();

        if (user) {
          user
            .update({})
            .then(response => {
              commit("SET_CURRENT_USER", response);
              resolve(response);
            })
            .catch(error => {
              console.error("Failed to update user account: %o", error);
              reject(error);
            });
        } else {
          // check if there is anything in the local storage to clean up
          commit("SET_CURRENT_USER", null);
        }

      });
    },

    /**
     * This should be deleted at some point
     */
    getCurrentUser({ state }) {
      console.log("User Object", state.GoTrueAuth.currentUser());
    },

    /**
     * Initialises a GoTrue instance. This method also checks if user is in a local environment  based on the URL.
     * this updates the `app/SET_DEV_ENV` flag. This facilitates a zero-config setup as a developer can input their
     * netlify URL in the UI (see the the `SetNetlifyURL.vue` component). Inspired from the official Netlify
     * Identity widget.
     * @param {*} store - vuex store object
     */
    initAuth({ commit, rootGetters, dispatch }) {
      // https://stackoverflow.com/questions/5284147/validating-ipv4-addresses-with-regexp/57421931#57421931
      const IPv4Pattern = /\b((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\b/;
      const hostName = document.location.hostname;
      const APIUrl = `https://${hostName}/.netlify/identity`;
      const initNewGoTrue = APIUrl => {
        return new GoTrue({
          APIUrl: APIUrl,
          audience: "",
          setCookie: true
        });
      };

      // Detect if app is being run in a development environment, if so a flag is set to indicate this so that it is
      // possible to set the URL for the netlify identity in the login component.
      // TODO : Move this logic into a separate action.
      if (hostName.match(IPv4Pattern) || hostName === "localhost") {
        console.log("Looks like you are in a dev environment", hostName);
        commit("app/SET_DEV_ENV", true, { root: true });

        console.log(
            "Initialising Go True client with",
            `https://${rootGetters["app/siteURL"]}/.netlify/identity`
        );
        commit(
            "SET_GOTRUE",
            initNewGoTrue(
                `https://${rootGetters["app/siteURL"]}/.netlify/identity`
            )
        );

        this.subscribe(mutation => {
          if (mutation.type === "app/SET_SITE_URL") {
            console.log(
                "Re-initialising Go True client with",
                rootGetters["app/siteURL"]
            );
            commit(
                "SET_GOTRUE",
                initNewGoTrue(
                    `https://${rootGetters["app/siteURL"]}/.netlify/identity`
                )
            );
          }
        });

        dispatch("attemptRefresh");
        return;
      }

      commit("SET_GOTRUE", initNewGoTrue(APIUrl));
      dispatch("attemptRefresh");
    },

    requestPasswordRecover({ state }, email) {
      return new Promise((resolve, reject) => {
        state.GoTrueAuth.requestPasswordRecovery(email)
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
      });
    },

    attemptPasswordRecovery({ state, commit }, token) {
      return new Promise((resolve, reject) => {
        state.GoTrueAuth.recover(token)
            .then(response => {
              console.log("Signing in user with recovery token");
              commit("SET_CURRENT_USER", response);
              resolve(response);
            })
            .catch(error => {
              console.error("Failed to verify recover token: %o", error);
              reject();
            });
      });
    },

    updateUserAccount({ state, commit }, userData) {
      //TODO : fix bug in this action - https://github.com/chiubaca/vue-netlify-fauna-starter-kit/issues/12
      return new Promise((resolve, reject) => {
        const user = state.GoTrueAuth.currentUser();
        user
            .update(userData)
            .then(response => {
              if (state.currentUser.user_metadata.full_name !== userData.data.full_name) {
                commit("SET_CURRENT_USER", response);
              }
              if (state.currentUser.user_metadata.timezone !== userData.data.timezone) {
                commit("SET_CURRENT_USER", response);
              }
              resolve(response);
            })
            .catch(error => {
              console.error("Failed to update user account: %o", error);
              reject(error);
            });
      });
    },
    updateUserPassword({ state }, userData) {
      //TODO : fix bug in this action - https://github.com/chiubaca/vue-netlify-fauna-starter-kit/issues/12
      return new Promise((resolve, reject) => {
        const user = state.GoTrueAuth.currentUser();
        user
            .update(userData)
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              console.error("Failed to update user account: %o", error);
              reject(error);
            });
      });
    },
  }
};
