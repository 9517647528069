import { q, client } from "../helpers/init-db";

/**
 *
 * @param {object} blockData - object containing the block data
 */
export function createProject(projectData) {
  const me = q.CurrentIdentity();
  return client
    .query(
      q.Create(q.Collection("projects"), {
        data: {
          ...projectData,
          owner: me
        },
        permissions: {
          read: me,
          write: me
        }
      })
    )
    .then(resp => resp);
}

export function getProjects() {
  const me = q.CurrentIdentity();
  return client
    .query(
        q.Map(q.Paginate(q.Match(q.Index('projects_by_owner'), me )), ref =>
            q.Get(ref)
        )
    ).then(resp => resp);
}

/**
 *
 * @param {object} refID - fauna ref object
 */
export function deleteProject(refID) {
  return client
      .query(q.Delete(q.Ref(q.Collection('projects'), refID)))
      .then(resp => resp);
}

/**
 *
 * @param {object} blockRefID - faunaDb journal collection reference ID
 * @param {object} blockData - object of data to update
 */
export function updateProject(refID, projectData) {
  return client
    .query(
      q.Update(q.Ref(q.Collection("projects"), refID), {
        data: {
          ...projectData
        }
      })
    )
    .then(resp => resp);
}
