import { q, client } from "../helpers/init-db";

export function getBoards() {
  const me = q.CurrentIdentity();
  return client
      .query(
          q.Map(q.Paginate(q.Match(q.Index('boards_by_owner'), me )), ref =>
              q.Get(ref)
          )
      ).then(resp => resp);
}

/**
 *
 * @param {object} boardData - object containing the board data
 */
export function createBoard(boardData) {
  const me = q.CurrentIdentity();
  return client
      .query(
          q.Create(q.Collection("boards"), {
            data: {
              ...boardData,
              owner: me
            },
            permissions: {
              read: me,
              write: me
            }
          })
      )
      .then(resp => resp);
}

/**
 *
 * @param {object} boardRefID - faunaDb journal collection reference ID
 * @param {object} boardData - object of data to update
 */
export function updateBoard(refID, boardData) {
  return client
      .query(
          q.Update(q.Ref(q.Collection("boards"), refID), {
            data: {
              ...boardData
            }
          })
      )
      .then(resp => resp);
}
