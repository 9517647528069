<template>
  <div
    :id="'block-' + id"
    :ref="'block-' + id"
    class="col-span-1 shadow-xl flex flex-col text-center bg-white rounded-xl shadow relative border-4 h-auto"
    :class="['bg-' + color + '-100 border-' + color + '-900', {'opacity-0': isNew}]"
  >

    <div v-if="hasError"
     class="absolute w-full h-full bg-red-200 opacity-90 z-50 text-red-900 font-medium pt-5"
    >
      Oh no, something went wrong!<br />Please <a @click="reload()" class="underline cursor-pointer">reload</a>.
    </div>

    <div class="absolute z-10 w-full h-full">
      <div class="rounded-md h-full opacity-20 border-3"
           style="border-radius: 8px;"
           :style="'box-shadow: inset 0 0 100px 0 #' + colorDeep + '; border-color: #' + colorLight">
      </div>
    </div>

    <div :class="'bg-' + color + '-200 border-' + color + '-300'"
      class="absolute w-full bottom-0 left-0"
      :style="progressStyle"
      style="transition: height 0.4s ease-in-out;"
    ></div>

    <div
      class="z-20 px-4 pt-4 relative shadow-none flex items-center"
      :class="'bg-transparent border-' + color + '-300'"
    >
      <div class="flex-shrink-0">
        <span class="rounded-lg inline-flex p-2 block-icon"
          :class="'bg-' + color + '-50 border-' + color + '-300 border-2'">
          <BlockIcon
            @click="blockEdit"
            v-bind:type="faceType"
            v-bind:color="color"
            v-bind:isLoading="isLoading"
            v-bind:hasError="hasError"
          ></BlockIcon>
        </span>
      </div>
      <div class="flex-1">

        <svg
          @click="blockEdit"
          class="h-1 cursor-pointer z-10 text-gray-900 text-lg font-bold inline-block"
          style="font-family: 'Roboto Mono', monospace;width: 100%; height: 28px;">
          <text :style="'stroke: #' + colorOutline + ';'" text-anchor="middle" x="50%" y="24" fill="white" stroke-width="4" letter-spacing="2" paint-order="stroke">{{formattedTotal}}</text>
        </svg>

        <span @click="blockEdit" class="cursor-pointer font-bold" :class="'text-' + color + '-900'">{{title}}</span>
      </div>

      <button v-if="!embed" @click="blockEdit"
        class="z-20 top-1 left-1 absolute opacity-50 hover:opacity-100 rounded-md inline-flex text-gray-900 hover:text-gray-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        :class="'text-' + color + '-900'"
      >
        <svg class="w-4 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z"></path><path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd"></path></svg>
      </button>

      <button v-if="!embed"
        class="cursor-move block-drag-handle z-20 top-3 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute opacity-50 hover:opacity-100 rounded-md inline-flex text-gray-900 hover:text-gray-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        :class="'text-' + color + '-900'"
      >
        <svg class="w-6 h-6" stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="5" cy="9" r="1" /><circle cx="5" cy="15" r="1" /><circle cx="12" cy="9" r="1" /><circle cx="12" cy="15" r="1" /><circle cx="19" cy="9" r="1" /><circle cx="19" cy="15" r="1" />
        </svg>
      </button>

      <button v-if="!embed" @click="deleteBlock"
        class="z-20 top-1 right-1 absolute opacity-50 hover:opacity-100 rounded-md inline-flex text-gray-900 hover:text-gray-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        :class="'text-' + color + '-900'"
      >
        <span class="sr-only">Close</span>
        <!-- Heroicon name: x -->
        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
          <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
      </button>
    </div>
    <div class="z-20 pb-2">
      <span class="text-xs block leading-none" :class="'text-' + color + '-500'">&nbsp;</span>
    </div>
  </div>
</template>
<script>
  import {colors} from '../colors/colors.js';
  import BlockIcon from './BlockIcon.vue';
  import {useToast} from "vue-toastification";
  import {nextTick} from "vue";
  import dayjs from "dayjs";
  import relativeTime from "dayjs/plugin/relativeTime";
  import anime from "animejs";

  dayjs.extend(relativeTime);

  export default {
    name: 'BlockCollector',
    components: {
      BlockIcon
    },
    props: {
      id: String,
      title: String,
      color: String,
      size: Number,
      src: String,
      faceType: {
        default: 'default',
        type: String
      },
      elapsedTime: Number,
      preview: {
        default: false,
        type: Boolean
      },
      embed: {
        default: false,
        type: Boolean
      },
      embedPreview: {
        default: false,
        type: Boolean
      },
      local: {
        default: false,
        type: Boolean
      },
      isLoading: {
        default: false,
        type: Boolean
      },
      hasError: {
        default: false,
        type: Boolean
      },
      isNew: {
        default: false,
        type: Boolean
      },
      embedOptions: Object,
      totalTime: Number,
      activeSince: Number,
      elements: Array,
      allowTemplateAdd: Boolean,
      refID: String,
      increaseValue: Number,
      blockTick: Number
    },
    setup() {
      const toast = useToast();
      return { toast };
    },
    data() {
      return {
        elHeight: 0,
        elWidth: 0,
        increaseEl: [],
        decreaseEl: [],
        sizeOptionsHours: [
          {text: '00', value: 0},
          {text: '01', value: 1},
          {text: '02', value: 2},
          {text: '03', value: 3},
          {text: '04', value: 4},
          {text: '05', value: 5},
          {text: '06', value: 6},
          {text: '07', value: 7},
          {text: '08', value: 8}
        ],
        sizeOptionsMinutes: [
          {text: '00', value: 0},
          {text: '15', value: 0.25},
          {text: '30', value: 0.5},
          {text: '45', value: 0.75}
        ],
        colors
      }
    },
    computed: {
      isActive() {
        return !!this.activeSince;
      },
      colorLight() {
        let colorIndex = this.colors.findIndex(item => item.value === this.color );
        return this.colors[colorIndex].colors[100];
      },
      colorDark() {
        let colorIndex = this.colors.findIndex(item => item.value === this.color );
        return this.colors[colorIndex].colors[500];
      },
      colorDeep() {
        let colorIndex = this.colors.findIndex(item => item.value === this.color );
        return this.colors[colorIndex].colors[600];
      },
      colorGrad() {
        let colorIndex = this.colors.findIndex(item => item.value === this.color );
        return this.colors[colorIndex].colors[600];
      },
      colorOutline() {
        let colorIndex = this.colors.findIndex(item => item.value === this.color );
        return this.colors[colorIndex].colors[900];
      },
      totalElements() {
        let total = 0;
        let now = this.blockTick;
        let totalValue = 0;
        this.elements.forEach((element) => {
          // calculate duration for each element
          if (element.source === 'Block') {
            if (element.end) {
              let duration = element.end - element.start;
              // duration is in milliseconds, turn it into hours
              let hours = (duration / (1000 * 60 * 60)).toFixed(3);
              totalValue = totalValue + (hours * element.value);
            } else {
              let duration = now - element.start;
              // duration is in milliseconds, turn it into hours
              let hours = (duration / (1000 * 60 * 60)).toFixed(3);
              totalValue = totalValue + (hours * element.value);
            }
          } else {
            totalValue = totalValue + element.value;
          }
        });

        total = (totalValue + Number.EPSILON).toFixed(2);
        if (total < 0) total = 0;

        return total;
      },
      formattedTotal() {
        return this.totalElements + "/" + this.size;
      },
      progressStyle() {
        let height = this.elHeight - 8;
        let width = this.elWidth;
        let size = this.size;
        let total = this.totalElements;

        let progress_perc = total / size;
        let bar_perc = progress_perc * height;

        if (bar_perc >= height) {
          bar_perc = height;
        }

        let borderTopWidth = '0';
        if (bar_perc) {
          borderTopWidth = '2px';
        }

        return {
          height: bar_perc + 'px',
          width: (width - 8) + 'px',
          left: '4px',
          bottom: '4px',
          borderTopWidth: borderTopWidth
        }
      },
      textClass() {
        if (this.totalElements) {
          return 'text-' + this.color + '-800 bg-' + this.color + '-300 hover:text-' + this.color + '-200 hover:bg-' + this.color + '-700 border-' + this.color + '-800';
        } else {
          return 'text-' + this.color + '-500 bg-' + this.color + '-200 border-' + this.color + '-300 cursor-not-allowed';
        }
      },
      lastElement() {
        if (this.elements.length) {
          let lastEl = this.elements[this.elements.length - 1];
          return this.lastElementTitle + dayjs(lastEl.start).from(this.blockTick);
        }
        return '- -';
      },
      showControls() {
        if (this.embed === true && this.embedOptions.mode === 'view') {
            return false;
        } else {
          return true;
        }
      },
      lastElementTitle() {
        const lastItem = this.elements[this.elements.length - 1];
        if (typeof lastItem.title !== 'undefined' && lastItem.title !== '_default' && lastItem.title !== '') {
          return lastItem.title + ' ';
        }
        return '';
      },
    },
    methods: {
      reset() {
        this.$emit('blockReset', this.id);
      },
      blockEdit() {
        if (this.preview) {
          this.toast.warning(`Please save your changes first to be able to edit the block.`);
        } else {
          this.$emit('blockEditStart', this.id);
        }
      },
      deleteBlock() {
        if (this.preview) {
          this.toast.warning(`Please save your changes first to be able to edit the block.`);
        } else {
          this.$emit('blockDelete', this.id, this.refID);
        }
      },
      reload() {
        window.location.reload();
      },
    },
    mounted() {
      if (this.isNew) {
        console.log('is new');
        this.$emit('blockAdded', this.id);
        const block = document.querySelector("#block-" + this.id);
        //block.style.transform = "scale(0.5)";
        block.style.zIndex = 10000000;

        anime({
          targets: '#block-' + this.id,
          easing: 'linear',
          duration: 380,
          opacity: [1, 1],
          rotate: [3, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          delay: 600,
          //scale: [{value: 0.5},{value: 1}],
          matrix3d: [
            '0.1, 0, 0, 0, 0, 0.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.318, 0, 0, 0, 0, 0.318, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.448, 0, 0, 0, 0, 0.448, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.688, 0, 0, 0, 0, 0.688, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.983, 0, 0, 0, 0, 0.983, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.011, 0, 0, 0, 0, 1.011, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.131, 0, 0, 0, 0, 1.131, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.147, 0, 0, 0, 0, 1.147, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.056, 0, 0, 0, 0, 1.056, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.129, 0, 0, 0, 0, 1.129, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.097, 0, 0, 0, 0, 1.097, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.023, 0, 0, 0, 0, 1.023, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.996, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.985, 0, 0, 0, 0, 0.985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.965, 0, 0, 0, 0, 0.965, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.967, 0, 0, 0, 0, 0.967, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.988, 0, 0, 0, 0, 0.988, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.996, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
            '1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1'
          ],
          complete: () => {
            anime.remove('#block-' + this.id);
            block.style.zIndex = 'auto';
          }
        });
      }

      nextTick(() => {
        if (this.$refs['block-' + this.id] !== null) {
          this.elHeight = this.$refs['block-' + this.id].clientHeight;
          this.elWidth = this.$refs['block-' + this.id].clientWidth;
        }
      });
    }
  }
</script>
