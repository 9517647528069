export const presets = [
  {
    boards: [
      {
        "title": "Default",
        "id": "fc1449ff-c698-49ac-81f8-58fcb6e09905",
        "color": "",
        "rows": [
          {
            "id": "d8cbfe75-a837-4ce2-9b12-1c0d824ca3bc",
            "title": "",
            "order": 0,
            "cols": [
              {
                "title": "",
                "id": "3762f5f8-a141-417c-9c38-df4a685a26ce",
                "order": 1,
                "data": [
                  {
                    "id": "9db3511d-4697-41eb-8efd-4a30467aa846",
                    "block": "a775b556-928a-42b2-a1c8-0b7a07bcd79b"
                  }
                ],
                "color": null
              },
              {
                "title": "",
                "id": "f31bf359-318b-4c0a-8f6a-65a0cceeeb19",
                "order": 2,
                "data": [
                  {
                    "id": "cfaffa93-5155-45bf-bb86-9f4e062145c0",
                    "block": "c808a659-6202-46d9-aed0-7da83ae3950a"
                  }
                ],
                "color": null
              },
              {
                "title": "",
                "id": "4ddca12a-220c-4944-bd44-c049901c3510",
                "order": 3,
                "data": [
                  {
                    "id": "ad69b914-e7bf-49af-858a-2cf1be5538e2",
                    "block": "072638f5-d364-42fa-af50-8f90d485c25f"
                  }
                ],
                "color": null
              },
              {
                "title": "",
                "id": "4201be54-a511-4887-8abb-afe9dfa8346a",
                "order": 4,
                "data": [],
                "color": null
              }
            ],
            "color": "bluegray"
          },
          {
            "id": "4abdf3fd-3876-4f57-b275-a30536edafd0",
            "title": "🍅 POMODORO",
            "order": 0,
            "cols": [
              {
                "title": "",
                "id": "26e4ee28-b990-4e2d-b78b-320dc89c5720",
                "order": 1,
                "data": [
                  {
                    "id": "439718f9-a27f-4858-862a-d41f1c5ac313",
                    "block": "e023cef0-9c04-4e76-b3e5-6dc612eeba3d"
                  }
                ],
                "color": null
              },
              {
                "title": "",
                "id": "29cad49f-eb4e-40b9-9e6c-43f9243d78dd",
                "order": 2,
                "data": [
                  {
                    "id": "658fd92a-4262-4e9d-a464-561174a2af7a",
                    "block": "632b5f88-1748-45f2-b0f5-dc1fa5439039"
                  }
                ],
                "color": null
              },
              {
                "title": "",
                "id": "5f8fd026-46c4-4924-aed4-409bc38badaa",
                "order": 3,
                "data": [
                  {
                    "id": "5adc8808-4e13-44da-a91d-1a3c2e265c58",
                    "block": "a236f622-b4f6-4a48-acf0-9979d71b512f"
                  }
                ],
                "color": null
              },
              {
                "title": "",
                "id": "9af999c7-3598-4c3d-ac94-b52d6b2dc5da",
                "order": 4,
                "data": [
                  {
                    "id": "f164b8a9-2897-4c26-9cd0-ba3141d9ac90",
                    "block": "96b7c998-db0b-4581-aa0f-bc14f565349b"
                  }
                ],
                "color": null
              }
            ],
            "color": "red"
          },
          {
            "title": "WORK",
            "order": 1,
            "id": "d8c76b31-5449-4e57-bb7c-a570bfc2df40",
            "color": "yellow",
            "cols": [
              {
                "title": "",
                "id": "529a2f1c-913d-4c6f-9dcf-d461a112c735",
                "order": 1,
                "data": [
                  {
                    "id": "fe5dc85a-c789-4c57-9fec-dd75de57fbdc",
                    "block": "0351b8b4-d015-48e0-ac84-2c9957d46a82"
                  }
                ],
                "color": null
              },
              {
                "title": "",
                "id": "55457525-1f78-4b4f-9ab8-169124ffe7fa",
                "order": 2,
                "data": [
                  {
                    "id": "f2c06b48-e5a2-4aaa-882e-74097434b7af",
                    "block": "472eb5aa-07e0-42d6-ab8f-88e7100416c3"
                  }
                ],
                "color": null
              },
              {
                "title": "",
                "id": "2484055f-bc52-4d53-8fdd-406d380dfe1d",
                "order": 3,
                "data": [
                  {
                    "id": "c7bfb063-0995-470d-9fba-915e3f528e97",
                    "block": "2edb2124-6ed6-4c6c-b781-7af29012a98b"
                  }
                ],
                "color": null
              },
              {
                "title": "",
                "id": "845fcb20-d194-4a10-8f7d-e6e293313f51",
                "order": 4,
                "data": [
                  {
                    "id": "c6e0aa60-1da9-4bac-8de6-3a71fe8ab3f7",
                    "block": "c9054191-f4c9-4819-b86e-5a209df20860"
                  }
                ],
                "color": null
              }
            ]
          }
        ]
      }
    ],
    blocks: [
      {
        "id": "0351b8b4-d015-48e0-ac84-2c9957d46a82",
        "title": "Inbox",
        "type": "Block",
        "color": "yellow",
        "size": 2,
        "increaseValue": 1,
        "faceType": "default",
        "elapsedTime": 0,
        "totalTime": 0,
        "activeSince": 0,
        "activeCountdownSince": 0,
        "elements": [],
        "refID": null
      },
      {
        "id": "a775b556-928a-42b2-a1c8-0b7a07bcd79b",
        "title": "Water",
        "type": "BlockCounter",
        "color": "blue",
        "size": 6,
        "increaseValue": 1,
        "faceType": "default",
        "elapsedTime": 0,
        "totalTime": 0,
        "activeSince": 0,
        "activeCountdownSince": 0,
        "elements": [],
        "refID": null
      },
      {
        "id": "c808a659-6202-46d9-aed0-7da83ae3950a",
        "title": "Coffee",
        "type": "BlockCounter",
        "color": "bluegray",
        "size": 5,
        "increaseValue": 1,
        "faceType": "coffee",
        "elapsedTime": 0,
        "totalTime": 0,
        "activeSince": 0,
        "activeCountdownSince": 0,
        "elements": [],
        "refID": null
      },
      {
        "id": "072638f5-d364-42fa-af50-8f90d485c25f",
        "title": "Read 10 pages",
        "type": "BlockCounter",
        "color": "yellow",
        "size": 10,
        "increaseValue": 1,
        "faceType": "book",
        "elapsedTime": 0,
        "totalTime": 0,
        "activeSince": 0,
        "activeCountdownSince": 0,
        "elements": [],
        "refID": null
      },
      {
        "id": "96b7c998-db0b-4581-aa0f-bc14f565349b",
        "title": "25 Minutes break",
        "type": "BlockCountdown",
        "color": "warmgray",
        "size": 1500,
        "increaseValue": 1,
        "faceType": "donut",
        "elapsedTime": 0,
        "totalTime": 0,
        "activeSince": 0,
        "activeCountdownSince": 0,
        "elements": [],
        "refID": null
      },
      {
        "id": "a236f622-b4f6-4a48-acf0-9979d71b512f",
        "title": "5 Minutes break",
        "type": "BlockCountdown",
        "color": "warmgray",
        "size": 300,
        "increaseValue": 1,
        "faceType": "banana",
        "elapsedTime": 0,
        "totalTime": 0,
        "activeSince": 0,
        "activeCountdownSince": 0,
        "elements": [],
        "refID": null
      },
      {
        "id": "632b5f88-1748-45f2-b0f5-dc1fa5439039",
        "title": "50 Minutes",
        "type": "BlockCountdown",
        "color": "red",
        "size": 3000,
        "increaseValue": 1,
        "faceType": "money",
        "elapsedTime": 0,
        "totalTime": 0,
        "activeSince": 0,
        "activeCountdownSince": 0,
        "elements": [],
        "refID": null
      },
      {
        "id": "e023cef0-9c04-4e76-b3e5-6dc612eeba3d",
        "title": "Pomodoro daily",
        "type": "BlockCounter",
        "color": "red",
        "size": 7,
        "increaseValue": 1,
        "faceType": "star",
        "elapsedTime": 0,
        "totalTime": 0,
        "activeSince": 0,
        "activeCountdownSince": 0,
        "elements": [],
        "refID": null
      },
      {
        "id": "2edb2124-6ed6-4c6c-b781-7af29012a98b",
        "title": "Admin",
        "type": "Block",
        "color": "lime",
        "size": 3,
        "increaseValue": 1,
        "faceType": "default",
        "elapsedTime": 0,
        "totalTime": 0,
        "activeSince": 0,
        "activeCountdownSince": 0,
        "elements": [],
        "refID": null
      },
      {
        "id": "c9054191-f4c9-4819-b86e-5a209df20860",
        "title": "Secret Project",
        "type": "Block",
        "color": "green",
        "size": 1,
        "increaseValue": 1,
        "faceType": "default",
        "elapsedTime": 0,
        "totalTime": 0,
        "activeSince": 0,
        "activeCountdownSince": 0,
        "elements": [],
        "refID": null
      },
      {
        "id": "472eb5aa-07e0-42d6-ab8f-88e7100416c3",
        "title": "Project #1",
        "type": "Block",
        "color": "amber",
        "size": 2,
        "increaseValue": 1,
        "faceType": "default",
        "elapsedTime": 0,
        "totalTime": 0,
        "activeSince": 0,
        "activeCountdownSince": 0,
        "elements": [],
        "refID": null
      }
    ]
  }
];
