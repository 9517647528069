import { q, client } from "../helpers/init-db";

/**
 *
 * @param {object} journalData - object containing the title of journal, could contain other data too in the future
 */
export function createBlockTemplate(blockTemplateData) {
  const me = q.CurrentIdentity();
  return client
    .query(
      q.Create(q.Collection("blockTemplates"), {
        data: {
          ...blockTemplateData
        },
        permissions: {
          read: me,
          write: me
        }
      })
    )
    .then(resp => resp);
}

export function getBlockTemplates() {
  return client
    .query(
      q.Map(q.Paginate(q.Match(q.Ref("indexes/all_block_templates"))), ref =>
          q.Get(ref)
      )
    )
    .then(resp => resp);
}

/**
 *
 * @param {object} refID - fauna ref object
 */
export function deleteBlockTemplate(refID) {
  return client
    .query(q.Delete(q.Ref(q.Collection('blockTemplates'), refID)))
    .then(resp => resp);
}
