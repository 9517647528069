<template>
  <div class="fixed inset-0 overflow-hidden z-50">
    <div class="absolute inset-0 overflow-hidden">
      <section class="absolute inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16" aria-labelledby="slide-over-heading">
        <div class="w-screen max-w-2xl">
          <div class="h-full flex flex-col bg-white shadow-xl">

            <div class="min-h-0 flex-1 flex flex-col overflow-y-scroll">
              <!-- Header -->
              <div
                class="px-4 py-6 sm:px-6"
                :class="'bg-' + block.color + '-100'"
              >
                <div class="flex items-center justify-between space-x-3">
                  <div class="flex-none">
                    <div
                      class="items-center w-10 h-10 rounded-full"
                      :class="'bg-' + block.color + '-200'"
                    >
                      <BlockIcon
                        v-bind:type="block.faceType"
                        v-bind:color="block.color"
                        v-bind:isPreview="true"
                      ></BlockIcon>
                    </div>
                  </div>
                  <div class="space-y-1 flex-grow">
                    <h2 class="text-lg font-medium text-gray-900">
                      {{block.title}}
                    </h2>
                  </div>
                  <div class="h-7 flex-none items-center">
                    <button
                      @click="blockEditStop"
                      class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                      type="button"
                    >
                      <span class="sr-only">Close panel</span>
                      <!-- Heroicon name: outline/x -->
                      <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>

              <div class="border-b border-gray-200 mt-6">
                <div class="px-6">
                  <nav class="-mb-px flex space-x-6">
                    <!-- Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" -->
                    <a @click="mode = 'info'"
                       href="#"
                       class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                       :class="{'border-indigo-500 text-indigo-600 hover:text-indigo-600 hover:border-indigo-500' : mode === 'info'}"
                    >
                      Info
                    </a>
                    <a @click="mode = 'elements'"
                       href="#"
                       class="border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                       :class="{'border-indigo-500 text-indigo-600 hover:text-indigo-600 hover:border-indigo-500' : mode === 'elements'}"
                    >
                      Log entries
                    </a>
                  </nav>
                </div>
              </div>

              <!-- Divider container -->
              <div v-if="mode==='info'" class="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                <div class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <div>
                    <label for="block_title" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                      Title
                    </label>
                  </div>
                  <div class="sm:col-span-2">
                    <input v-model="title" type="text" name="block_title" id="block_title" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
                  </div>
                </div>

                <div class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
                  <div>
                    <label for="block_size" class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
                      Goal
                    </label>
                  </div>
                  <div class="sm:col-span-2">
                    <input v-model="size" type="number" min="0" name="block_size" id="block_size" class="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md">
                  </div>
                </div>

                <!-- Colors -->
                <div class="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:px-6 sm:py-5">
                  <div>
                    <h3 class="text-sm font-medium text-gray-900">
                      Color
                    </h3>
                  </div>
                  <div class="sm:col-span-2">
                    <div v-for="colorOption in colors" v-bind:key="colorOption.value"
                        class="inline-block rounded-full h-10 w-10 m-2 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 shadow border-white border-2"
                        :class="'bg-' + colorOption.value + '-400 hover:bg-' + colorOption.value + '-700 focus:ring-' + colorOption.value + '-500'"
                        @click="blockChangeColor(colorOption.value)"
                    >
                      <svg v-if="colorOption.value === color" className="inline-block h-6 w-6 rounded-full" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" /></svg>
                    </div>
                  </div>
                </div>

                <!-- Faces -->
                <div class="space-y-2 px-4 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:px-6 sm:py-5">
                  <div>
                    <h3 class="text-sm font-medium text-gray-900">
                      Icon
                    </h3>
                  </div>
                  <div class="sm:col-span-2">
                      <div v-for="faceOption in faces" v-bind:key="faceOption.value"
                          class="inline-block relative rounded-md h-10 w-10 m-2 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 shadow border-white border-2"
                          :class="'bg-' + color + '-50 hover:bg-' + color + '-700 focus:ring-' + color + '-500'"
                          @click="blockChangeFace(faceOption.value)"
                      >
                        <BlockIcon
                          v-bind:type="faceOption.value"
                          v-bind:isPreview="true"
                          v-bind:color="color"
                        ></BlockIcon>
                        <svg v-if="faceOption.value === faceType" class="rounded-full absolute top-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" /></svg>
                      </div>
                  </div>
                </div>

              </div>

              <!-- Time entries -->
              <div v-else-if="mode==='elements'" class="py-6 space-y-6 sm:py-0 sm:space-y-0 sm:divide-y sm:divide-gray-200">
                <div class="flex flex-col space-y-1 px-4 sm:space-y-0 sm:px-6 sm:py-5">
                  <div class="">
                    <div class="py-2 align-middle inline-block min-w-full">
                      <div v-if="activeBlock" class="px-2 pt-0 pb-4 whitespace-nowrap text-sm text-yellow-500">
                        To update log entries, stop the currently running block.
                      </div>
                      <div class="shadow border-b border-gray-200 sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200">
                          <thead class="bg-gray-50">
                          <tr>
                            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Title
                            </th>
                            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Value
                            </th>
                            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Time
                            </th>
                            <th scope="col" class="relative px-6 py-3">
                              <span class="sr-only">Actions</span>
                            </th>
                          </tr>
                          </thead>
                          <tbody>

                          <tr class="bg-white" v-for="(element, index) in elementsProcessed" v-bind:key="element.id">
                            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                              {{element.title}}
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {{element.totalValue}}
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              {{element.start}}<br />
                              {{element.end}}
                            </td>
                            <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              <a v-if="!activeBlock" @click="elementDelete(index)" class="cursor-pointer text-indigo-600 hover:text-indigo-900">Delete</a>
                            </td>
                          </tr>

                          <!-- More items... -->
                          </tbody>
                        </table>


                      </div>
                    </div>
                    <div class="py-2 align-middle inline-block min-w-full">
                      <div class="shadow border-b border-gray-200 sm:rounded-lg">
                        <table class="min-w-full divide-y divide-gray-200">
                          <tbody>
                            <tr class="bg-white">
                              <td class="pl-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                <label for="element_value" class="block-inline text-sm font-medium text-gray-700 mr-2">Increase by</label><br />
                                <input
                                  type="number"
                                  id="element_value"
                                  v-model="elementAddIncreaseValue"
                                  name="element_value"
                                  :disabled="activeBlock"
                                  class="mt-1 w-28 block-inline border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                              </td>
                              <td class="pl-0 py-4 pr-2 whitespace-nowrap text-sm text-gray-500">
                                <label for="element_start" class="block-inline text-sm font-medium text-gray-700 mr-2">Time</label>
                                <litepie-datepicker
                                  as-single
                                  ref="litePieDatePicker"
                                  :formatter="formatter"
                                  v-model="elementAddStart"
                                  id="element_start"
                                  :disabled="activeBlock"
                                />
                              </td>
                              <td class="pr-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                <button :disabled="activeBlock" @click="elementAdd" class="mt-4 text-indigo-600 hover:text-indigo-900">Add</button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <!-- Action buttons -->
            <div class="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
              <div class="space-x-3 flex justify-end">
                <button @click="blockEditStop" type="button" class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Cancel
                </button>
                <button @click="blockUpdate" type="button" class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
  import {colors} from '../colors/colors.js';
  import {v4 as uuidv4} from "uuid";
  import BlockIcon from './BlockIcon.vue';
  import LitepieDatepicker from "litepie-datepicker";
  import {ref} from "vue";
  import * as dayjs from "dayjs";
  import {mapGetters} from "vuex";
  import { copyText } from 'vue3-clipboard';
  import anime from "animejs";

  export default {
    name: 'BlockEdit',
    components: {
      BlockIcon,
      LitepieDatepicker,
    },
    props: {
      id: String,
      block: Object,
      allowTemplateAdd: Boolean,
      blocks: Array,
    },
    setup() {
      const formatter = ref({
        date: 'DD MMM YYYY HH:mm',
        month: 'MMM'
      });

      const doCopy = (url) => {
        copyText(url, undefined, (error) => {
          if (!error) {
            anime({
              targets: '#copy-status',
              opacity: [{value: 100}, {value: 0}],
              easing: 'easeInOutSine',
              duration: 1200
            });
          }
        })
      };

      return {
        formatter, doCopy
      };
    },
    data() {
      return {
        mode: 'info',
        title: JSON.parse(JSON.stringify(this.block.title)),
        increaseValue: JSON.parse(JSON.stringify(this.block.increaseValue)),
        color: JSON.parse(JSON.stringify(this.block.color)),
        size: JSON.parse(JSON.stringify(this.block.size)),
        elements: JSON.parse(JSON.stringify(this.block.elements)),
        faceType: this.getFace(this.block.faceType),
        elementAddStart: '',
        elementAddIncreaseValue: '',
        iconEdited: null,
        faces: [
          {text: 'default', value: 'default'},
          {text: 'flex', value: 'flex'},
          {text: 'money', value: 'money'},
          {text: 'coffee', value: 'coffee'},
          {text: 'death', value: 'death'},
          {text: 'happy', value: 'happy'},
          {text: 'headboom', value: 'headboom'},
          {text: 'sad', value: 'sad'},
          {text: 'cow', value: 'cow'},
          {text: 'star', value: 'star'},
          {text: 'beer', value: 'beer'},
          {text: 'radioactive', value: 'radioactive'},
          {text: 'banana', value: 'banana'},
          {text: 'rainbow', value: 'rainbow'},
          {text: 'toiletpaper', value: 'toiletpaper'},
          {text: 'phone', value: 'phone'},
          {text: 'donut', value: 'donut'},
          {text: 'book', value: 'book'},
          {text: 'tea', value: 'tea'},
          {text: 'wine', value: 'wine'},
          {text: 'cocktail', value: 'cocktail'}
        ],
        colors,
      }
    },
    computed: {
      ...mapGetters("auth", ["currentUser"]),
      elementsProcessed () {
        //let initTime = null;
        let elementsNew = [];

        // get the highest start
        if (this.elements.length > 0) {
          const now = new Date().getTime();
          // calculate durations and format date to display
          this.elements.forEach((element) => {
            let title = '- -';
            let totalValue = 0;

            if (typeof element.title !== 'undefined' && element.title !== '_default' && element.title !== '') {
              title = element.title;
            }
              // calculate duration
            if (element.source === 'Block') {
              if (element.end) {
                let duration = element.end - element.start;
                // duration is in milliseconds, turn it into hours
                let hours = (duration / (1000 * 60 * 60)).toFixed(3);
                totalValue = hours * element.value;
              } else {
                let duration = now - element.start;
                // duration is in milliseconds, turn it into hours
                let hours = (duration / (1000 * 60 * 60)).toFixed(3);
                totalValue = hours * element.value;
              }
            } else {
              totalValue = element.value;
            }

            elementsNew.push({
              id: element.id,
              title: title,
              totalValue: totalValue.toFixed(3),
              start: dayjs(element.start).format('HH:mm DD MMM YYYY')
            });
          });
        }
        return elementsNew;
      },
      activeBlock() {
        let blockActive = this.blocks.find(block => block.activeSince !== 0 && block.type === 'Block');
        if (blockActive) return true;
        return false;
      }
    },
    methods: {
      getFace(faceType) {
        if (typeof faceType === 'undefined') {
          return "default";
        }
        return faceType;
      },
      blockEditStop() {
        this.$emit('blockEditStop');
      },
      blockChangeColor(color) {
        this.color = color;
      },
      blockChangeFace(face) {
        this.faceType = face;
      },
      blockUpdate() {
        // prepare structure to save
        const data = {};
        if (this.title !== this.block.title) {
          data.title = JSON.parse(JSON.stringify(this.title));
        }
        if (this.size !== this.block.size) {
          data.size = JSON.parse(JSON.stringify(this.size)) / 1;
        }
        if (this.increaseValue !== this.block.increaseValue) {
          data.increaseValue = JSON.parse(JSON.stringify(this.increaseValue)) / 1;
        }
        if (this.color !== this.block.color) {
          data.color = JSON.parse(JSON.stringify(this.color));
        }
        if (this.faceType !== this.block.faceType) {
          data.faceType = JSON.parse(JSON.stringify(this.faceType));
        }
        if (JSON.stringify(this.elements) !== JSON.stringify(this.block.elements)) {
          data.elements = JSON.parse(JSON.stringify(this.elements));
        }

        if (Object.keys(data).length) {
          this.$emit('blockUpdate', this.block.refID, this.block.id, data);
        }
        this.blockEditStop();
      },
      blockSaveTemplate() {
        const blockTemplate = {
          title: this.title,
          color: this.color,
          size: this.size,
          faceType: this.faceType
        };
        this.$emit('blockSaveTemplate', blockTemplate);
      },
      elementAdd() {
        if (!this.block.activeSince) {
          let start = new Date(this.elementAddStart);
          let increaseValue = this.elementAddIncreaseValue / 1;

          const randomId = uuidv4();
          const element = {
            id: randomId,
            type: 'track',
            start: start.getTime(),
            end:  start.getTime(),
            value: increaseValue,
            source: 'Custom',
          };
          this.elements.push(element);
        }
      },
      elementDelete(index) {
        this.elements.splice(index, 1);
      },
      dateFormat(tm) {
        let d = new Date(tm);
        return d.getHours() + ':' + d.getMinutes() + ' ' + d.getDate() + '/' + (d.getMonth()+1) + '/' + d.getFullYear();
      },
    }
  }
</script>
