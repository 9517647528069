import { q, client } from "../helpers/init-db";

/**
 *
 * @param {object} blockData - object containing the block data
 */
export function createBlock(blockData) {
  const block = JSON.parse(JSON.stringify(blockData));
  delete block.isNew;
  const me = q.CurrentIdentity();
  return client
    .query(
      q.Create(q.Collection("blocks"), {
        data: {
          ...block,
          owner: me
        },
        permissions: {
          read: me,
          write: me
        }
      })
    )
    .then(resp => resp);
}

export function getBlocks() {
  const me = q.CurrentIdentity();
  return client
    .query(
        q.Map(q.Paginate(q.Match(q.Index('blocks_by_owner'), me )), ref =>
            q.Get(ref)
        )
    ).then(resp => resp);
}

export function deleteAllBlocks(blocks) {
  let refs = blocks.map(a => a.refID);
  if (refs.length) {
    refs.forEach(function (ref) {
      deleteBlock(ref);
    });
  }
}

/**
 *
 * @param {object} refID - fauna ref object
 */
export function deleteBlock(refID) {
  return client
      .query(q.Delete(q.Ref(q.Collection('blocks'), refID)))
      .then(resp => resp);
}

/**
 *
 * @param {object} blockRefID - faunaDb journal collection reference ID
 * @param {object} blockData - object of data to update
 */
export function updateBlock(refID, blockData) {
  return client
    .query(
      q.Update(q.Ref(q.Collection("blocks"), refID), {
        data: {
          ...blockData
        }
      })
    )
    .then(resp => resp);
}
