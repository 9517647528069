<template>
  <div id="tracker-nav" class="pt-3 pb-4 mx-auto shadow sticky top-16 z-30" style="background-color: rgba(255,255,255,0.2);">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex-none md:flex">
      <div class="flex md:flex-none md:flex-grow">
        <div v-if="!boardGridEdit" class="mt-2 border-r-2 pr-3 mr-3 border-gray-100">
          <button
            data-im-id="button-grid-edit"
            type="button"
            @click="boardGridEditing"
            :disabled="isLoading"
            class="relative border-gray-600 border-2 border-b-3 items-center p-2 pb-3 rounded-lg shadow-sm text-gray-600 bg-gray-200 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-50"
            style="box-shadow: inset 0px -6px 0px #9CA3AF, inset 0 -7px 0 0 #F3F4F6, inset 0px 1px 0px 1px #F3F4F6;"
          >
            <svg v-if="isLoading" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path></svg>
            <div v-else>
              <svg class="h-6 w-6 inline-block" fill="currentColor" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z" />
              </svg>
              <span class="font-bold ml-1">Build</span>
            </div>
            <svg height="10" width="10" class="absolute top-0 right-0" :class="[jobQueue.length ? 'text-yellow-400' : 'text-green-600']">
              <circle cx="5" cy="5" r="4" fill="currentColor" />
            </svg>
          </button>
        </div>
        <template v-if="!boardGridEdit">

          <div class="mt-2 inline-flex flex-grow md:flex-grow-0 border-r-2 mr-3 border-gray-100">
            <button
              data-im-id="button-block-add"
              type="button"
              @click="blockAdd('Block','block')"
              :disabled="!allowBlockAdd"
              class="mr-3 border-green-600 border-2 border-b-3 items-center p-2 pb-3 rounded-lg shadow-sm text-green-600 bg-green-200 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-50 origin-bottom"
              style="box-shadow: inset 0px -6px 0px #34D399, inset 0 -7px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5;"
            >
              <svg v-if="!allowBlockAdd" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path></svg>
              <svg v-else class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd"></path></svg>
            </button>
            <button
              data-im-id="button-blockcounter-add"
              type="button"
              @click="blockAdd('BlockCounter', 'blockcounter')"
              :disabled="!allowBlockAdd"
              class="mr-3 border-green-600 border-2 border-b-3 items-center p-2 pb-3 rounded-lg shadow-sm text-green-600 bg-green-200 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-50 origin-bottom"
              style="box-shadow: inset 0px -6px 0px #34D399, inset 0 -7px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5;"
            >
              <svg v-if="!allowBlockAdd" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path></svg>
              <svg v-else class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5 3a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V5a2 2 0 00-2-2H5zm9 4a1 1 0 10-2 0v6a1 1 0 102 0V7zm-3 2a1 1 0 10-2 0v4a1 1 0 102 0V9zm-3 3a1 1 0 10-2 0v1a1 1 0 102 0v-1z" clip-rule="evenodd"></path></svg>
            </button>
            <button
              data-im-id="button-blockcountdown-add"
              type="button"
              @click="blockAdd('BlockCountdown', 'blockcountdown')"
              :disabled="!allowBlockAdd"
              class="mr-3 border-green-600 border-2 border-b-3 items-center p-2 pb-3 rounded-lg shadow-sm text-green-600 bg-green-200 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-50 origin-bottom"
              style="box-shadow: inset 0px -6px 0px #34D399, inset 0 -7px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5;"
            >
              <svg v-if="!allowBlockAdd" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path></svg>
              <svg v-else class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"></path></svg>
            </button>
            <button
              data-im-id="button-blockprogress-add"
              type="button"
              @click="blockAdd('BlockProgress', 'blockprogress')"
              :disabled="!allowBlockAdd"
              class="mr-3 border-green-600 border-2 border-b-3 items-center p-2 pb-3 rounded-lg shadow-sm text-green-600 bg-green-200 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-50 origin-bottom"
              style="box-shadow: inset 0px -6px 0px #34D399, inset 0 -7px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5;"
            >
              <svg v-if="!allowBlockAdd" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path></svg>
              <svg v-else class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path><path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path></svg>
            </button>
            <button
              data-im-id="button-blockcollector-add"
              type="button"
              @click="blockAdd('BlockCollector', 'blockcollector')"
              :disabled="!allowBlockAdd"
              class="mr-3 border-green-600 border-2 border-b-3 items-center p-2 pb-3 rounded-lg shadow-sm text-green-600 bg-green-200 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-50 origin-bottom"
              style="box-shadow: inset 0px -6px 0px #34D399, inset 0 -7px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5;"
            >
              <svg v-if="!allowBlockAdd" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path></svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" viewBox="0 0 20 20" fill="currentColor">
                <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z" />
              </svg>
            </button>
          </div>

          <div
            class="inline-flex mt-2 text-right md:flex-grow-0"
          >
            <button
              data-im-id="button-deepwork"
              :disabled="!timerActive || isLoading"
              @click="deepWorkModal = true"
              type="button"
              class="mr-3 border-gray-600 border-2 border-b-3 items-center p-2 pb-3 rounded-lg shadow-sm text-gray-600 bg-gray-200 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-50"
              style="box-shadow: inset 0px -6px 0px #9CA3AF, inset 0 -7px 0 0 #F3F4F6, inset 0px 1px 0px 1px #F3F4F6;"
              :class="{'opacity-40' : !timerActive, 'opacity-100' : timerActive}"
            >
              <svg v-if="isLoading" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path></svg>
              <svg v-else class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z"></path></svg>
            </button>
          </div>
        </template>
        <template v-else>
          <div class="relative mt-2">
            <button @click="modeSwatchEdit = !modeSwatchEdit" class="relative w-15 mr-3 rounded-md pl-2 pr-2 py-2 text-left cursor-pointer focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <svg v-if="boardData[0].mode === 'compact'" xmlns="http://www.w3.org/2000/svg" class="w-7 h-7 inline-block" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <rect x="4" y="4" width="6" height="6" rx="1" />
                <rect x="14" y="4" width="6" height="6" rx="1" />
                <rect x="4" y="14" width="6" height="6" rx="1" />
                <rect x="14" y="14" width="6" height="6" rx="1" />
              </svg>
              <svg v-else xmlns="http://www.w3.org/2000/svg" class="w-7 h-7 inline-block" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <rect x="4" y="4" width="6" height="16" rx="2" />
                <rect x="14" y="4" width="6" height="10" rx="2" />
              </svg>
              <svg xmlns="http://www.w3.org/2000/svg" class="inline-block h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>

            <ul v-if="modeSwatchEdit" class="whitespace-normal absolute z-50 mt-1 w-60 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              <li
                @click="modeBoardSwatch('default')"
                class="cursor-pointer text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-blue-50">
                <div class="flex">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-7 h-7 flex-shrink" width="44" height="44" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <rect x="4" y="4" width="6" height="16" rx="2" />
                    <rect x="14" y="4" width="6" height="10" rx="2" />
                  </svg>
                  <div class="font-medium ml-3 block">
                    <span class="leading-7">Default</span><br />
                    <span class="text-sm leading-normal font-normal text-gray-400">
                      Blocks display with height defined by their size.
                    </span>
                  </div>
                </div>
              </li>
              <li
                @click="modeBoardSwatch('compact')"
                class="cursor-pointer text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 border-t hover:bg-blue-50">
                <div class="flex">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-7 h-7 flex-shrink" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <rect x="4" y="4" width="6" height="6" rx="1" />
                    <rect x="14" y="4" width="6" height="6" rx="1" />
                    <rect x="4" y="14" width="6" height="6" rx="1" />
                    <rect x="14" y="14" width="6" height="6" rx="1" />
                  </svg>
                  <div class="font-medium ml-3 block">
                    <span class="leading-7">Compact</span><br />
                    <span class="text-sm leading-normal font-normal text-gray-400">
                      Blocks display with the same height.
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="relative mt-2">
            <button @click="colorGridSwatchEdit('board')" type="button" class="cursor-pointer relative w-auto mr-3 align-center rounded-md pl-2 pr-2 py-2 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <div class="flex items-center">
                <span :class="'bg-' + boardData[0].color + '-400 ' + boardData[0].color + ' border-' + boardData[0].color + '-600'" class="border-2 flex-shrink-0 inline-block h-7 w-7 rounded-full"></span>
              </div>
            </button>
            <ul v-if="colorSwatchEdit === 'board'" class="absolute z-50 mt-1 w-40 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
              <li
                @click="colorBoardSwatch('board', '')"
                class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9">
                <div class="flex items-center">
                  <span class="flex-shrink-0 inline-block h-4 w-4 rounded-full"
                        :class="'bg-white'"
                  ></span>
                  <span class="font-normal ml-3 block truncate">
                    None
                  </span>
                </div>
                <span v-if="boardData[0].color === '' || boardData[0].color === null" class="text-green-600 absolute inset-y-0 right-0 flex items-center pr-4">
                  <!-- Heroicon name: solid/check -->
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                  </svg>
                </span>
              </li>
              <li
                v-for="color in bgGradients" v-bind:key="color.value"
                @click="colorBoardSwatch('gradient', color.value)"
                class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9">
                <div class="flex items-center">
                  <!-- Online: "bg-green-400", Not Online: "bg-gray-200" -->
                  <span class="flex-shrink-0 inline-block h-4 w-4 rounded-full"
                        :class="color.value"
                  ></span>
                  <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                  <span class="font-normal ml-3 block truncate">
                    {{color.text}}
                  </span>
                </div>
                <span v-if="boardData[0].color === color.value" class="text-green-600 absolute inset-y-0 right-0 flex items-center pr-4">
                  <!-- Heroicon name: solid/check -->
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                  </svg>
                </span>
              </li>
              <li
                v-for="color in colors" v-bind:key="color.value"
                @click="colorBoardSwatch('board', color.value)"
                class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9">
                <div class="flex items-center">
                  <!-- Online: "bg-green-400", Not Online: "bg-gray-200" -->
                  <span class="flex-shrink-0 inline-block h-4 w-4 rounded-full"
                        :class="'bg-' + color.value + '-400'"
                  ></span>
                  <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                  <span class="font-normal ml-3 block truncate">
                    {{color.text}}
                  </span>
                </div>
                <span v-if="boardData[0].color === color.value" class="text-green-600 absolute inset-y-0 right-0 flex items-center pr-4">
                  <!-- Heroicon name: solid/check -->
                  <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                  </svg>
                </span>
              </li>
            </ul>
          </div>

          <div>
            <button @click="boardGridEditing" type="button"
              class="mt-2 relative border-gray-600 border-2 border-b-3 items-center p-2 pb-3 rounded-lg shadow-sm text-gray-600 bg-gray-200 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-50"
              style="box-shadow: inset 0px -6px 0px #9CA3AF, inset 0 -7px 0 0 #F3F4F6, inset 0px 1px 0px 1px #F3F4F6;"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <span class="font-bold ml-1">Cancel</span>
            </button>
            <button @click="boardGridEditUpdate" type="button"
              class="mt-2 ml-3 border-green-600 border-2 border-b-3 items-center p-2 pb-3 rounded-lg shadow-sm text-green-600 bg-green-200 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-50"
              style="box-shadow: inset 0px -6px 0px #34D399, inset 0 -7px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5;"
            >
              <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 inline-block" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd" />
              </svg>
              <span class="font-bold ml-1">Save</span>
            </button>
          </div>
        </template>
      </div>
      <div class="flex second-col mt-2 md:mt-0">
        <div data-im-id="timer-session" class="text-left md:text-right mt-1 md:mt-0 md:flex-grow md:border-l-2 md:ml-3 md:pl-1">
          <p class="text-xs text-gray-500 uppercase">Session</p>
          <h2 v-if="blocks.length" class="z-10 text-gray-900 md:pl-2 text-2xl md:text-3xl font-bold" style="font-family: 'Roboto Mono', monospace;">{{timerSession}}</h2>
          <span v-else class="z-10 text-gray-300 md:pl-2 text-2xl md:text-3xl font-bold" style="font-family: 'Roboto Mono', monospace;">00:00:00</span>
        </div>
        <div data-im-id="timer-total" class="flex-grow md:flex-shrink md:flex-none mt-1 md:mt-0 text-left md:text-right border-l-2 ml-3 pl-1">
          <p class="text-xs pl-2 text-gray-500 uppercase">Total</p>
          <h2 v-if="blocks.length" class="z-10 text-gray-900 pl-2 text-2xl md:text-3xl font-bold" style="font-family: 'Roboto Mono', monospace;">{{timerTotal}}</h2>
          <span v-else class="z-10 text-gray-300 pl-2 text-2xl md:text-3xl font-bold" style="font-family: 'Roboto Mono', monospace;">00:00:00</span>
        </div>
        <div class="mt-2 text-right border-l-2 ml-3 pl-3">
          <button data-im-id="button-freshstart" type="button" @click="freshStart('all')" :disabled="isLoading || boardGridEdit"
            class="inline-flex items-center p-2 pb-3 border-2 border-b-3 border-blue-900 border-transparent rounded-md shadow-sm text-blue-900 bg-blue-100 hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            style="box-shadow: inset 0px -6px 0px #93C5FD, inset 0 -7px 0 0 #EFF6FF, inset 0px 1px 0px 1px #EFF6FF;"
          >
            <svg v-if="isLoading || boardGridEdit" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path></svg>
            <div v-else>
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 inline-block" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" clip-rule="evenodd" />
              </svg>
              <span class="font-bold ml-1">Fresh start</span>
            </div>
          </button>
        </div>
      </div>
    </div>

    <div v-if="blocksTemplates.length" class="hidden md:flex md:visible flex-grow items-left pb-5 pt-5 border-b border-gray-200">
      <transition-group name="bounce">
        <span class="relative z-0 inline-flex shadow-sm rounded-md"
          v-for="blockTemplate in blocksTemplates"
          v-bind:key="blockTemplate.id"
        >
          <button
            :disabled="!allowBlockAdd"
            @click="blockAddFromTemplate(blockTemplate)"
            class="mr-3 relative inline-flex items-center px-4 py-3 rounded-md border-2 text-sm font-medium text-white focus:z-10 focus:outline-none focus:ring-1"
            :class="'border-' + blockTemplate.color + '-900 bg-' + blockTemplate.color + '-500 hover:bg-' + blockTemplate.color + '-700 focus:ring-' + blockTemplate.color + '-500 focus:border-' + blockTemplate.color + '-500'"
          >
            {{blockTemplate.title}} ({{blockTemplate.size}})
            <button @click.stop="blockTemplateDelete(blockTemplate.id, blockTemplate.refID)" class="z-20 top-0 right-0 absolute opacity-50 hover:opacity-100 rounded-md inline-flex text-gray-900 hover:text-gray-1000 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
              <span class="sr-only">Close</span>
              <!-- Heroicon name: x -->
              <svg class="h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
              </svg>
            </button>
          </button>

        </span>
      </transition-group>
      <span
        class="opacity-60 mr-3 px-3 py-3 w-20 bg-gray-100 inline-flex z-0 relative rounded-md text-sm font-medium"
        v-for="placeholder in templatePlaceholders"
        v-bind:key = "placeholder.id"
      >
      </span>
    </div>

  </div>
  <main id="tracker-main" class="pb-10 pt-2 overflow-x-auto">
    <div v-if="isLoading" class="max-w-7xl px-4 mx-auto sm:px-6 lg:px-8 text-center pt-2" style="width: 80rem;">
      <div class="inline-flex text-base leading-6 font-medium rounded-md text-white text-gray-600 cursor-not-allowed">
        <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
        Loading...
      </div>
    </div>
    <div v-if="boardGridEdit">
      <div class="max-w-7xl px-4 mx-auto sm:px-6 lg:px-8" style="width: 80rem;">
        <draggable
          :list="boardData[0].rows"
          group="rows"
          itemKey="id"
          handle=".row-drag-handle"
        >
          <template #item="{ element, index }">
            <div class="rounded bg-opacity-80 my-3" :class="'bg-' + element.color + '-50'">
              <div class="flex items-center">
                <div class="flex-shrink row-drag-handle cursor-move">
                  <svg class="w-6 h-6" stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="9" cy="5" r="1" /><circle cx="9" cy="12" r="1" /><circle cx="9" cy="19" r="1" /><circle cx="15" cy="5" r="1" /><circle cx="15" cy="12" r="1" /><circle cx="15" cy="19" r="1" />
                  </svg>
                </div>
                <div class="flex-shrink font-medium p-1">
                  <input v-model="element.title"
                   :class="'text-' + element.color + '-500 placeholder-' + element.color + '-300'"
                   class="inline-block p-1 text-left font-medium tracking-wider bg-transparent" placeholder="Empty Row Title" />
                </div>
                <div class="flex-shrink font-medium text-gray-500">
                  <div class="relative">
                    <button @click="colorGridSwatchEdit(element.id)" type="button" class="relative w-9 rounded-md pl-2 pr-2 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                      <div class="flex items-center">
                        <span :class="'bg-' + element.color + '-400 border-' + element.color + '-600'" class="border-2 flex-shrink-0 inline-block h-5 w-5 rounded-full"></span>
                      </div>
                    </button>
                    <ul v-if="colorSwatchEdit === element.id" class="absolute z-50 mt-1 w-40 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                      <li
                        @click="colorGridSwatch(element, '')"
                        class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9">
                        <div class="flex items-center">
                          <span class="flex-shrink-0 inline-block h-4 w-4 rounded-full"
                                :class="'bg-white'"
                          ></span>
                          <span class="font-normal ml-3 block truncate">
                            None
                          </span>
                        </div>
                        <span v-if="element.color === '' || element.color === null" class="text-green-600 absolute inset-y-0 right-0 flex items-center pr-4">
                          <!-- Heroicon name: solid/check -->
                          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                          </svg>
                        </span>
                      </li>
                      <li
                        v-for="color in colors" v-bind:key="color.value"
                        @click="colorGridSwatch(element, color.value)"
                        class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9">
                        <div class="flex items-center">
                          <!-- Online: "bg-green-400", Not Online: "bg-gray-200" -->
                          <span class="flex-shrink-0 inline-block h-4 w-4 rounded-full"
                                :class="'bg-' + color.value + '-400'"
                          ></span>
                          <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                          <span class="font-normal ml-3 block truncate">
                            {{color.text}}
                          </span>
                        </div>
                        <span v-if="element.color === color.value" class="text-green-600 absolute inset-y-0 right-0 flex items-center pr-4">
                          <!-- Heroicon name: solid/check -->
                          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                          </svg>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="flex-shrink flex align-middle mx-2 mr-4 text-xs border-l-2 border-gray-100 pl-4">
                  <button @click="element.freshStart = !element.freshStart" type="button"
                          class="mt-1 bg-gray-200 relative inline-flex flex-shrink-0 h-5 w-9 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          :class="{'bg-indigo-600': element.freshStart}"
                          role="switch" aria-checked="false">
                    <span class="sr-only">Use setting</span>
                    <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                    <span class="translate-x-0 pointer-events-none relative inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                          :class="{'translate-x-4': element.freshStart}"
                    >
                      <!-- Enabled: "opacity-0 ease-out duration-100", Not Enabled: "opacity-100 ease-in duration-200" -->
                      <span class="opacity-0 ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                            aria-hidden="true"
                            :class="{'opacity-100 ease-in duration-200': !element.freshStart}"
                      >
                        <svg class="h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
                          <path d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                      </span>
                          <!-- Enabled: "opacity-100 ease-in duration-200", Not Enabled: "opacity-0 ease-out duration-100" -->
                      <span class="opacity-0 ease-out duration-100 absolute inset-0 h-full w-full flex items-center justify-center transition-opacity"
                            :class="{'opacity-100 ease-in duration-200': element.freshStart}"
                            aria-hidden="true">
                        <svg class="h-3 w-3 text-indigo-600" fill="currentColor" viewBox="0 0 12 12">
                          <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                        </svg>
                      </span>
                    </span>
                  </button>
                  <span class="inline-flex pl-2.5 pr-0 py-1.5 text-xs font-medium text-gray-700">Fresh start</span>
                </div>
                <div class="flex-grow font-medium text-gray-500 border-l-2 border-gray-100 pl-4">
                  <button
                    @click="boardAddColumn(index)"
                    :disabled="element.cols.length >= limits.columns"
                    type="button" class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                    Add Column
                  </button>
                </div>
                <button
                  class="flex-shrink cursor-pointer"
                  @click="boardRemoveRow(index)"
                >
                  <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </button>
              </div>

            <draggable
              :list="element.cols"
              :group="element.id"
              itemKey="id"
              handle=".column-drag-handle"
              class="grid gap-6 p-2"
              :class="'grid-cols-' + element.cols.length"
            >
              <template #item="{ element }">
                <div class="rounded bg-opacity-80" :class="'bg-' + element.color + '-100'">
                  <div class="flex items-center justify-between">
                    <div class="flex-shrink">
                      <div class="relative">
                        <button @click="colorGridSwatchEdit(element.id)" type="button" class="relative w-8 rounded-md pl-2 pr-2 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                          <div class="flex items-center">
                            <span :class="'bg-' + element.color + '-400 border-' + element.color + '-600'" class="border-2 flex-shrink-0 inline-block h-4 w-4 rounded-full"></span>
                          </div>
                        </button>
                        <ul v-if="colorSwatchEdit === element.id" class="absolute z-50 mt-1 w-40 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                          <li
                                  @click="colorGridSwatch(element, '')"
                                  class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9">
                            <div class="flex items-center">
                            <span class="flex-shrink-0 inline-block h-4 w-4 rounded-full"
                                  :class="'bg-white'"
                            ></span>
                              <span class="font-normal ml-3 block truncate">
                              None
                            </span>
                            </div>
                            <span v-if="element.color === '' || element.color === null" class="text-green-600 absolute inset-y-0 right-0 flex items-center pr-4">
                            <!-- Heroicon name: solid/check -->
                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                            </svg>
                          </span>
                          </li>
                          <li
                                  v-for="color in colors" v-bind:key="color.value"
                                  @click="colorGridSwatch(element, color.value)"
                                  class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9">
                            <div class="flex items-center">
                              <!-- Online: "bg-green-400", Not Online: "bg-gray-200" -->
                              <span class="flex-shrink-0 inline-block h-4 w-4 rounded-full"
                                    :class="'bg-' + color.value + '-400'"
                              ></span>
                              <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                              <span class="font-normal ml-3 block truncate">
                              {{color.text}}
                            </span>
                            </div>
                            <span v-if="element.color === color.value" class="text-green-600 absolute inset-y-0 right-0 flex items-center pr-4">
                            <!-- Heroicon name: solid/check -->
                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                              <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                            </svg>
                          </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="column-drag-handle cursor-move">
                      <svg class="w-6 h-6" stroke="currentColor" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/><circle cx="5" cy="9" r="1" /><circle cx="5" cy="15" r="1" /><circle cx="12" cy="9" r="1" /><circle cx="12" cy="15" r="1" /><circle cx="19" cy="9" r="1" /><circle cx="19" cy="15" r="1" />
                      </svg>
                    </div>
                    <button
                      class="flex-shrink cursor-pointer"
                      @click="boardRemoveColumn(index, element.id)"
                      :disabled="element.data.length > 0"
                    >
                      <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    </button>
                  </div>
                  <div class="flex">
                    <div class="flex-grow font-medium text-gray-500">
                      <input
                        v-model="element.title"
                        :class="'text-' + element.color + '-500 placeholder-' + element.color + '-300'"
                        class="mx-2 placeholder-gray-300 p-2 text-left font-medium text-gray-500 tracking-wider bg-transparent w-full" placeholder="Empty Column Title" />
                    </div>
                  </div>
                  <div class="p-2">
                    <draggable
                      :list="element.data"
                      group="blocks"
                      itemKey="id"
                      handle=".block-drag-handle"
                      class="grid gap-y-4 grid-cols-1"
                    >
                      <template #item="{element}">
                        <div>
                          <component
                            :is="blocks[blockIndexById(element.block)].type || 'Block'"
                            v-bind="blocks[blockIndexById(element.block)]"
                            :allowTemplateAdd="allowTemplateAdd"
                            :blockTick="blockTick"
                            :boardMode="boardData[0].mode"
                            :preview="true"
                            :projects="this.$store.state.projects.projects"
                            @block-reset="blockReset"
                            @block-delete="blockDelete"
                            @block-edit-title="blockEditTitle"
                            @block-edit-size="blockEditSize"
                            @block-edit-color="blockEditColor"
                            @block-edit-cancel="blockEditCancel"
                            @block-edit-start="blockEditStart"
                            @block-notification-update="blockNotificationUpdate"
                            @block-update="blockUpdate"
                            @block-add-time="blockAddTime"
                            @block-remove-time="blockRemoveTime"
                            @block-save-template="blockSaveTemplate"
                            @element-start="elementStart"
                            @element-end="elementEnd"
                            @element-increase="elementIncrease"
                            @element-decrease="elementDecrease"
                            @countdown-start="countdownStart"
                            @countdown-end="countdownEnd"
                            @handle-tracking-change="handleTrackingChange"
                          />
                        </div>
                      </template>
                    </draggable>

                  </div>
                </div>
              </template>
            </draggable>
            </div>
          </template>
          <template #footer>
            <button
              class="inline-block w-auto mx-2 h-10 bg-gray-600 text-white 00 m-2 p-2 rounded"
              @click="boardAddRow()"
              :disabled="boardData[0].rows.length >= limits.rows"
            >
              Add Row
            </button>
          </template>
        </draggable>
      </div>
    </div>
    <div v-else>
      <div v-if="!currentUser && boards.length && boards[0].rows.length && !blocks.length" class="text-center max-w-7xl min-w-7xl px-4 mx-auto sm:px-6 lg:px-8" style="width: 80rem;">
        <button @click="dataPopulate" type="button" class="inline-flex items-center px-4 py-2 mb-4 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Curious? Populate with sample data.
        </button>
      </div>
      <div v-if="boards.length" class="grid gap-y-3 grid-cols-1 max-w-7xl min-w-7xl px-4 mx-auto sm:px-6 lg:px-8" style="width: 80rem;">
        <div class="block"
          v-for="(row, rowIndex) in boards[0].rows"
          v-bind:key="row.id"
           :id="'row-' + rowIndex"
        >
          <div class="p-2 rounded bg-opacity-80"
           :class="'bg-' + row.color + '-50'">
            <div class="flex">
              <div v-if="row.title"
                class="px-3 py-3 pt-0 text-left font-medium tracking-wider"
                :class="'text-' + row.color + '-500'"
              >{{row.title}}</div>
              <div v-if="row.freshStart" class="flex-grow text-right">
                <button data-im-id="button-freshstart" type="button" @click="freshStart(row.id)"
                  class="inline-flex items-center p-0.5 border-2 border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2"
                  :class="[row.color ? 'shadow-sm text-' + row.color + '-600 bg-' + row.color + '-50 hover:bg-' + row.color + '-100 border-' + row.color + '-600 focus:ring-' + row.color + '-500'
                          : 'shadow-sm text-blue-600 bg-blue-50 hover:bg-blue-100 border-blue-600 focus:ring-blue-500']"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" clip-rule="evenodd" />
                  </svg>
                </button>
              </div>
            </div>
            <div class="grid gap-6" :class="'grid-cols-' + row.cols.length">
          <div v-for="col in row.cols" v-bind:key="col.id" class="rounded p-2 bg-opacity-80"
            :class ="' bg-' + col.color + '-100'">
            <draggable
              :list="col.data"
              group="blocks"
              itemKey="id"
              handle=".block-drag-handle"
              @change="boardChange"
              class="col-transition grid gap-y-4 grid-cols-1 h-full content-start min-h-16"
              :class="{'custom-outline': boardDrag}"
              :animation="200"
              @start="boardDrag=true"
              @end="boardDrag=false"
              drag-class="block-drag-class"
              ghost-class=""
              chosen-class=""
            >
              <template #header>
                <div
                  v-if="col.title"
                  :class="'text-' + col.color + '-500'"
                  class="px-2 py-0 pt-2 text-left font-medium tracking-wider">{{col.title}}</div>
              </template>
              <template #item="{element}">
                <div>
                  <component
                    :is="blocks[blockIndexById(element.block)].type || 'Block'"
                    v-bind="blocks[blockIndexById(element.block)]"
                    :allowTemplateAdd="allowTemplateAdd"
                    :blockTick="blockTick"
                    :boardMode="boards[0].mode"
                    :projects="this.$store.state.projects.projects"
                    @block-reset="blockReset"
                    @block-delete="blockDelete"
                    @block-added="blockAdded"
                    @block-edit-title="blockEditTitle"
                    @block-edit-size="blockEditSize"
                    @block-edit-color="blockEditColor"
                    @block-edit-cancel="blockEditCancel"
                    @block-edit-start="blockEditStart"
                    @block-notification-update="blockNotificationUpdate"
                    @block-update="blockUpdate"
                    @block-add-time="blockAddTime"
                    @block-remove-time="blockRemoveTime"
                    @block-save-template="blockSaveTemplate"
                    @element-start="elementStart"
                    @element-end="elementEnd"
                    @element-increase="elementIncrease"
                    @element-decrease="elementDecrease"
                    @countdown-start="countdownStart"
                    @countdown-end="countdownEnd"
                    @handle-tracking-change="handleTrackingChange"
                  />
                </div>
              </template>
            </draggable>
          </div>
        </div>
        </div>
        </div>
      </div>
      <div v-if="!currentUser" class="max-w-7xl px-4 mx-auto sm:px-6 lg:px-8 text-center pt-2 mb-5" style="width: 80rem;">
        <div class="rounded-md bg-yellow-50 p-4 shadow-md">
          <div class="flex">
            <div class="flex-shrink-0">
              <!-- Heroicon name: solid/exclamation -->
              <svg class="border-2 border-yellow-200 h-12 w-12 text-yellow-400 bg-yellow-100 p-2 rounded" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
              </svg>
            </div>
            <div class="ml-3 flex-grow text-left">
              <h3 class="text-md font-medium text-yellow-800">
                You are using free version of Kairo which does not store data.
              </h3>
              <div class="mt-1 text-sm text-yellow-700">
                <p>
                  Sign up and subscribe to keep your data and access reports from anywhere.
                </p>
              </div>
            </div>
            <div class="flex-shrink">
              <router-link
                      to="signup"
                      class="mt-2 inline-flex items-center px-3 py-2 border-2 border-yellow-200 text-sm leading-4 font-medium rounded-md text-yellow-700 bg-yellow-100 hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
              >Start trial
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="anime-container bg-green-200 border-none w-8 h-8 absolute rounded-md opacity-0"></div>
    <div class="anime-container bg-green-200  border-none w-8 h-8 absolute rounded-md opacity-0"></div>
    <div class="anime-container bg-green-200  border-none w-8 h-8 absolute rounded-md opacity-0"></div>
    <div class="anime-container bg-green-200  border-none w-8 h-8 absolute rounded-md opacity-0"></div>
    <div class="anime-container bg-green-200  border-none w-8 h-8 absolute rounded-md opacity-0"></div>
    <div class="anime-container bg-green-200  border-none w-8 h-8 absolute rounded-md opacity-0"></div>
    <div class="anime-container bg-green-200  border-none w-8 h-8 absolute rounded-md opacity-0"></div>
    <div class="anime-container bg-green-200  border-none w-8 h-8 absolute rounded-md opacity-0"></div>
    <div class="anime-container bg-green-200  border-none w-8 h-8 absolute rounded-md opacity-0"></div>
    <div class="anime-container bg-green-200  border-none w-8 h-8 absolute rounded-md opacity-0"></div>
    <div class="anime-container bg-green-200 border-0 border-green-600 w-8 h-8 absolute rounded-lg opacity-0"></div>

    <div v-if="toggleModal" class="fixed z-50 inset-0 overflow-y-auto">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <span class="inline-block align-middle h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">

          <div class="flex items-center">
            <div class="rounded-full bg-blue-100 text-blue-900 p-2">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z" clip-rule="evenodd" />
              </svg>
            </div>
            <div>
              <div class="flex items-center">
                <h1 class="ml-3 text-2xl font-bold leading-7 text-gray-900">
                  Fresh start
                </h1>
              </div>
              <div class="ml-3 flex flex-col">
                <div class="flex items-center text-sm text-gray-500 font-medium">
                  <p>Whether it's a new day or a new start.</p>
                </div>
              </div>
            </div>
          </div>

          <div class="border-t border-gray-200 mt-3">
            <div class="mt-3 text-center sm:mt-5">
              <div class="text-sm text-yellow-500 font-medium mb-2">
                <p class="text-center">
                  <svg class="w-6 h-6 inline-block" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"></path></svg>
                  <span v-if="toggleModal === 'all'">Resets all trackers on the board.</span>
                  <span v-else>Resets trackers in the selected row.</span>
                </p>
              </div>
              <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                Do you want to add tracked blocks to your reports?
              </h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">
                </p>
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
            <button v-if="currentUser" @click="resetAll" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
              NO
            </button>
            <button v-if="!currentUser" @click="resetBlocksAnon" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm">
              NO
            </button>
            <button v-if="currentUser" @click="freshStartSave" type="button" class="mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm">
              YES
            </button>
            <button v-else type="button" class="cursor-not-allowed mt-3 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-200 text-base font-medium text-gray-400 focus:outline-none sm:mt-0 sm:col-start-1 sm:text-sm">
              YES
            </button>
          </div>
          <div v-if="!currentUser" class="text-sm  text-gray-500 font-medium mt-1">* Reports are available only to paid plans.</div>
          <div class="mt-5 sm:mt-6">
            <button @click="toggleModal = !toggleModal" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:text-sm">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>

    <div v-if="deepWorkModal || deepWorkState" class="fixed z-50 inset-0 overflow-y-auto">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-black opacity-100"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-gray-900 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          <div>
            <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-200">
              <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z"></path></svg>
            </div>
            <div class="mt-3 text-center sm:mt-5">
              <h3 class="text-2xl leading-6 font-medium text-gray-200 border-b pb-4" id="modal-headline">
                Deep Work
              </h3>
              <div v-if="deepWorkState">
                <h2 v-if="blocks.length" class="z-10 text-gray-200 pl-2 text-3xl font-bold" style="font-family: 'Roboto Mono', monospace;">{{deepWorkTotal}}</h2>
              </div>
              <div v-if="!deepWorkState" class="text-gray-200">

                <div class="grid grid-cols-2 border-b mb-2 pb-2">
                  <div class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-4 sm:px-6 sm:py-1">
                    <div>
                      <label for="block_size_hours" class="block text-sm font-medium text-white sm:mt-px sm:pt-2">
                        Hours
                      </label>
                    </div>
                    <div class="sm:col-span-2">
                      <select v-model="deepWorkSizeHours" id="block_size_hours" name="block_size_hours" class="text-black mt-0 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm">
                        <option v-for="option in sizeOptionsHours" :value="option.value" v-bind:key="option.value">
                          {{ option.text }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="space-y-1 px-4 sm:space-y-0 sm:grid sm:grid-cols-1 sm:gap-4 sm:px-6 sm:py-1">
                    <div>
                      <label for="block_size_minutes" class="block text-sm font-medium text-white-900 sm:mt-px sm:pt-2">
                        Minutes
                      </label>
                    </div>
                    <div class="sm:col-span-2">
                      <select v-model="deepWorkSizeMinutes" id="block_size_minutes" name="block_size_minutes" class="text-black mt-0 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm">
                        <option v-for="option in sizeOptionsMinutes" :value="option.value" v-bind:key="option.value">
                          {{ option.text }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="relative flex text-left items-start pt-2 pb-2"
                v-for="item in deepChecklist" v-bind:key="item.id"
                >
                  <div class="h-8 w-8">
                    <input :id="item.id" name="comments" type="checkbox" class="focus:ring-green-500 h-8 w-8 text-green-600 border-gray-300 rounded">
                  </div>
                  <div class="ml-3 text-lg">
                    <label :for="item.id" class="font-medium text-gray-200">{{item.title}}</label>
                    <p class="text-gray-500 text-sm">{{item.description}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-5 sm:mt-6 text-center">
            <button v-if="!deepWorkState" @click="deepWorkStart" type="button" class="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm">
              Start Deep Work
            </button>
            <button v-if="deepWorkState === 'active'" @click="deepWorkStop" type="button" class="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 sm:text-sm">
              Stop Deep Work
            </button>
            <p v-if="deepWorkState === 'done'" class="text-gray-300 text-2xl">
              👏 Well done<br />
              <a @click="deepWorkDismiss" class="cursor-pointer inline-flex justify-center underline text-base text-gray-300 sm:text-sm">
                Close
              </a>
            </p>
          </div>
          <div v-if="!deepWorkState" class="mt-2 sm:mt-2 text-center">
            <a @click="deepWorkModal = false" class="cursor-pointer inline-flex justify-center underline text-base text-gray-300 sm:text-sm">
              Not now
            </a>
          </div>
        </div>
      </div>
    </div>

    <template v-if="blocks">
      <template v-for="block in blocks">
        <template
          v-for="collector in block.collectors"
          v-bind:key="collector"
        >
          <div v-if="blocks[blockIndexById(collector.id)]">
            <div
                    :id="'block-' + block.id + '-collector-' + collector.id"
                    class="absolute collector-anim items-center rounded-lg w-10 h-10 p-2 hidden"
                    :class="'bg-' + blocks[blockIndexById(collector.id)].color  + '-300'"
            ></div>
            <div
                    :id="'block-' + block.id + '-collector-' + collector.id"
                    class="absolute collector-anim items-center rounded-lg w-10 h-10 p-2 hidden"
                    :class="'bg-' + blocks[blockIndexById(collector.id)].color + '-300'"
            ></div>
            <div
                    :id="'block-' + block.id + '-collector-' + collector.id"
                    class="absolute collector-anim items-center rounded-lg w-10 h-10 p-2 hidden"
                    :class="'bg-' + blocks[blockIndexById(collector.id)].color + '-300'"
            ></div>
            <div
                    :id="'block-' + block.id + '-collector-' + collector.id"
                    class="absolute collector-anim items-center rounded-lg w-10 h-10 p-2 hidden"
                    :class="'bg-' + blocks[blockIndexById(collector.id)].color + '-300'"
            ></div>
            <div
                    :id="'block-' + block.id + '-collector-' + collector.id"
                    class="absolute collector-anim items-center rounded-lg w-10 h-10 p-2 hidden"
                    :class="'bg-' + blocks[blockIndexById(collector.id)].color + '-300'"
            ></div>
            <div
                    :id="'block-' + block.id + '-collector-' + collector.id"
                    class="absolute collector-anim items-center rounded-lg w-10 h-10 p-2 hidden"
                    :class="'bg-' + blocks[blockIndexById(collector.id)].color + '-300'"
            ></div>
            <div
              :id="'block-' + block.id + '-collector-' + collector.id"
              class="absolute collector-anim items-center rounded-lg w-10 h-10 p-2 hidden"
              :class="'bg-' + blocks[blockIndexById(collector.id)].color + '-50 border-' + blocks[blockIndexById(collector.id)].color + '-300 border-2'"
            >
              <BlockIcon
                v-bind:type="blocks[blockIndexById(collector.id)].faceType"
                v-bind:color="blocks[blockIndexById(collector.id)].color"
                v-bind:isPreview="true"
              ></BlockIcon>
            </div>
          </div>
        </template>
      </template>
    </template>
  </main>

  <div v-if="blockEdited.id" class="fixed inset-0 transition-opacity z-40" aria-hidden="true">
    <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
  </div>
  <transition
    enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
    leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
    enter-from-class="translate-x-full"
    enter-to-class="translate-x-0"
    leave-from-class="translate-x-0"
    leave-to-class="translate-x-full"
  >
    <component
      v-if="blockEdited.id"
      :is="blockEdited.type + 'Edit'"
      v-bind:key="blockEdited.id"
      v-bind:id="blockEdited.id"
      v-bind:block="blockEdited"
      v-bind:allowTemplateAdd="allowTemplateAdd"
      v-bind:projects="projects"
      v-bind:blocks="blocks"
      @block-edit-stop="blockEditStop"
      @block-update="blockUpdate"
      @block-save-template="blockSaveTemplate"
    ></component>
  </transition>
</template>

<script>
  import Block from '@/components/Block.vue';
  import BlockCounter from '@/components/BlockCounter.vue';
  import BlockCountdown from '@/components/BlockCountdown.vue';
  import BlockProgress from '@/components/BlockProgress.vue';
  import BlockCollector from '@/components/BlockCollector.vue';
  import BlockEdit from '@/components/BlockEdit.vue';
  import BlockCounterEdit from '@/components/BlockCounterEdit.vue';
  import BlockCountdownEdit from '@/components/BlockCountdownEdit.vue';
  import BlockProgressEdit from '@/components/BlockProgressEdit.vue';
  import BlockCollectorEdit from '@/components/BlockCollectorEdit.vue';
  import BlockIcon from '@/components/BlockIcon.vue';
  import { animationInterval } from '../utils/animationInterval';
  import {colors} from '../colors/colors.js';
  import {skins} from '../colors/skins.js';
  import {presets} from '../presets/free.js';
  import { v4 as uuidv4 } from 'uuid';
  import {
    createBlockTemplate,
    deleteBlockTemplate, getBlockTemplates
  } from "../models/BlockTemplatesModel";
  import {
    createBlock,
    deleteBlock,
    updateBlock,
    getBlocks
  } from "../models/BlocksModel";
  import {
    getBoards,
    createBoard,
    updateBoard
  } from "../models/BoardsModel";
  import {
    createReport
  } from "../models/ReportsModel";
  import {mapGetters, mapActions} from "vuex";
  import { useToast } from "vue-toastification";
  import draggable from 'vuedraggable';
  import {nextTick} from "vue";
  import { useNativeNotifications } from "vue3-native-notification";
  import Worker from "worker-loader!../workers/countdown.js";
  import {getProjects} from "../models/ProjectsModel";
  import anime from "animejs";

  const sfx = require('@/assets/sfx/alarm.mp3');

  export default {
    name: 'Tracker',
    emits: ["updateBG"],
    components: {
      draggable,
      Block,
      BlockCounter,
      BlockCountdown,
      BlockProgress,
      BlockCollector,
      BlockEdit,
      BlockCounterEdit,
      BlockCountdownEdit,
      BlockProgressEdit,
      BlockCollectorEdit,
      BlockIcon,
    },
    setup() {
      const toast = useToast();
      const nativeNotification = useNativeNotifications();
      return { toast, nativeNotification };
    },
    data() {
      return {
        boards: this.$store.state.tracker.boards,
        blocks: this.$store.state.tracker.blocks,
        blocksTemplates: this.$store.state.tracker.blocksTemplates,
        projects: this.$store.state.projects.projects,
        favicons: {},
        timerTotal: 0,
        timerSession: 0,
        blockTick: new Date().getTime() + 1000,
        worker: null,
        limits: {
          blocks: 22,
          blockTemplates: 8,
          columns: 6,
          rows: 4
        },
        isLoading: false,
        jobQueue: [],
        jobQueueInterval: '',
        jobQueueRunning: false,
        audio: null,
        sfx: sfx,
        toggleModal: false,
        deepWorkEnabled: true,
        deepWorkState: false,
        deepWorkModal: false,
        deepWorkTotal: "00:00:00",
        deepWorkSize: 0,
        deepChecklist: [
          {
            id: 1,
            title: "Disable notifications",
            description: "Turn off all your notifications that might disturb you."
          },
          {
            id: 2,
            title: "Tell others",
            description: "Is there anyone that can disturb you? Tell them not to."
          },
        ],
        colors,
        colorSwatchEdit: null,
        modeSwatchEdit: null,
        controller: new AbortController(),
        blockEdited: {},
        boardGridEdit: false,
        boardDrag: false,
        boardData: [],
        formData: {},
        sizeOptionsHours: [
          {text: '00', value: 0},
          {text: '01', value: 1},
          {text: '02', value: 2},
          {text: '03', value: 3},
          {text: '04', value: 4},
          {text: '05', value: 5},
          {text: '06', value: 6},
          {text: '07', value: 7},
          {text: '08', value: 8}
        ],
        sizeOptionsMinutes: [
          {text: '00', value: 0},
          {text: '15', value: 0.25},
          {text: '30', value: 0.5},
          {text: '45', value: 0.75}
        ],
        bgGradients: [
          {
            text: 'Gradient 1',
            value: 'bg-grad-1',
          },
          {
            text: 'Gradient 2',
            value: 'bg-grad-2',
          },
          {
            text: 'Gradient 3',
            value: 'bg-grad-3',
          },
          {
            text: 'Gradient 4',
            value: 'bg-grad-4',
          },
          {
            text: 'Gradient 5',
            value: 'bg-grad-5',
          },
          {
            text: 'Gradient 6',
            value: 'bg-grad-6',
          },
          {
            text: 'Gradient 7',
            value: 'bg-grad-7',
          },
          {
            text: 'Gradient 8',
            value: 'bg-grad-8',
          },
        ],
        demoColor: 0,
        presets,
        skins
      };
    },
    computed: {
      ...mapGetters("auth", ["currentUser"]),
      allowBlockAdd() {
        if (this.isLoading) return false;
        return !!(this.blocks.length < this.limits.blocks);
      },
      allowTemplateAdd() {
        return !!(this.blocksTemplates.length < this.limits.blockTemplates);
      },
      timerActive() {
        return this.blocks.some(function (block) {
          return block.activeSince !== 0;
        });
      },
      blockPlaceholders() {
        let placeholders = [];
        const count = 4 - this.blocks.length;
        for (let i = 0; i < count; i++) {
          placeholders.push(
            {
              id: i
            }
          );
        }
        return placeholders;
      },
      templatePlaceholders() {
        let placeholders = [];
        const count = 4 - this.blocksTemplates.length;
        for (let i = 0; i < count; i++) {
          placeholders.push(
              {
                id: i
              }
          );
        }
        return placeholders;
      },
      deepWorkSizeHours: {
        get() {
          return ~~this.deepWorkSize;
        },
        set(v) {
          this.deepWorkSize = v + this.deepWorkSizeMinutes;
        }
      },
      deepWorkSizeMinutes: {
        get() {
          return this.deepWorkSize - Math.floor(this.deepWorkSize);
        },
        set(v) {
          this.deepWorkSize = this.deepWorkSizeHours + v;
        }
      },
    },
    watch: {
      timerActive(newValue) {
        this.handleTrackingChange(newValue);
        this.calculateTimerTotal();
        this.calculateTimerSession();
        this.deepWork();
      },
    },
    methods: {
      ...mapActions(["resetEverything", "projectsResetAll"]),
      boardAddBlockAnime() {
        console.log('test 123123');
      },
      blockIndexById(blockId) {
        let index = this.blocks.findIndex(item => item.id === blockId);
        if (index > -1) {
          return index;
        }
      },
      freshStart(scope) {
        this.toggleModal = scope;
      },
      freshStartSave() {
        this.isLoading = true;
        let blocksData = JSON.parse(JSON.stringify(this.blocks));

        // We want to reset just a row, filter blocks
        if (this.toggleModal !== 'all') {
          let scopeBlocks = [];
          this.boards[0].rows.forEach((row) => {
            // Find the row id in question
            if (row.id === this.toggleModal) {
              row.cols.forEach((col) => {
                col.data.forEach((block) => {
                  scopeBlocks.push({id: block.block});
                });
              });
            }
          });
          let newBlocksData = blocksData.filter(block => {
            if (scopeBlocks.findIndex(item => item.id === block.id) >= 0) return true;
            return false;
          });
          blocksData = newBlocksData;
        }

        let from = new Date().getTime();
        let to = 0;

        // Clean up the data before saving
        blocksData.forEach((block, index) => {
          // Delete block if there are no elements
          if (block.elements.length) {
            delete blocksData[index].owner;
            delete blocksData[index].activeSince;

            // find the max and min within elements
            // close with end
            let block_start = 0;
            let block_end = 0;
            blocksData[index].elements.forEach((element, elIndex) => {
              if (block_start < element.start) block_start = element.start;

              // make sure elements with empty end are stopped
              if (element.end === null) {
                element.end = new Date().getTime();
                blocksData[index].elements[elIndex].end = element.end;
              }

              if (blocksData[index].type !== 'Block') {
                if (block_end < element.start) block_end = element.start;
              }

              if (block_end < element.end) block_end = element.end;
            });

            blocksData[index].from = block_start;
            if (block_start < from) from = block_start;

            blocksData[index].to = block_end;
            if (block_end > to) to = block_end;

          } else {
            delete blocksData[index];
          }
        });
        const reportData = {
          title: 'R',
          from: from,
          to: to,
          blocks: blocksData
        };
        createReport(reportData)
        .then(() => {
          this.resetPinned(blocksData)
          .then(() => {
            this.$store.commit('resetAll');
            this.totalTime = 0;
            this.timerTotal = '00:00:00';
            this.toggleModal = false;
            this.userContent();
          })
        });
      },
      resetBlocksAnon() {
        let blocksData = JSON.parse(JSON.stringify(this.blocks));

        // We want to reset just a row, filter blocks
        if (this.toggleModal !== 'all') {
          let scopeBlocks = [];
          this.boards[0].rows.forEach((row) => {
            // Find the row id in question
            if (row.id === this.toggleModal) {
              row.cols.forEach((col) => {
                col.data.forEach((block) => {
                  scopeBlocks.push({id: block.block});
                });
              });
            }
          });
          let newBlocksData = blocksData.filter(block => {
            if (scopeBlocks.findIndex(item => item.id === block.id) >= 0) return true;
            return false;
          });
          blocksData = newBlocksData;
        }

        blocksData.forEach((block) => {
          this.blockReset(block.id);
        });
        this.totalTime = 0;
        this.timerTotal = '00:00:00';
        this.toggleModal = false;
      },
      resetPinned(blocksData) {
        return new Promise((resolve) => {
          let promises = [];
          const toReset = blocksData;
          if (toReset.length) {
            toReset.forEach((block, index) => {
              toReset[index].activeSince = 0;
              toReset[index].elapsedTime = 0;
              toReset[index].elements = [];
              toReset[index].totalTime = 0;
              delete toReset[index].owner;
              promises.push(updateBlock(toReset[index].refID, toReset[index]));
            });
          }
          Promise.all(promises).then(()=>{
            resolve();
          });
        })
      },
      deleteUnpinned(blocksData) {
        return new Promise((resolve) => {
          let promises = [];
          // delete unpinned
          const toDelete = blocksData.filter(block => block.pinned !== true);
          if (toDelete.length) {
            toDelete.forEach((block) => {
              promises.push(deleteBlock(block.refID));
            });
            Promise.all(promises).then(()=>{
              resolve();
            });
          } else {
            resolve();
          }
        })
      },
      resetAll() {
        let blocksData = JSON.parse(JSON.stringify(this.blocks));

        // We want to reset just a row, filter blocks
        if (this.toggleModal !== 'all') {
          let scopeBlocks = [];
          this.boards[0].rows.forEach((row) => {
            // Find the row id in question
            if (row.id === this.toggleModal) {
              row.cols.forEach((col) => {
                col.data.forEach((block) => {
                  scopeBlocks.push({id: block.block});
                });
              });
            }
          });
          let newBlocksData = blocksData.filter(block => {
            if (scopeBlocks.findIndex(item => item.id === block.id) >= 0) return true;
            return false;
          });
          blocksData = newBlocksData;
        }

        this.resetPinned(blocksData)
            .then(() => {
              this.$store.commit('resetAll');
              this.totalTime = 0;
              this.timerTotal = '00:00:00';
              this.toggleModal = false;
              this.userContent();
            })
      },
      boardGridEditing() {
        if (this.boardGridEdit) {
          this.boardGridEdit = false;
          if (typeof this.boards[0].color !== "undefined") {
            if (this.boards[0].color.indexOf("bg-") >= 0) {
              this.$emit('updateBG', this.boards[0].color);
            } else {
              this.$emit('updateBG', 'bg-' + this.boards[0].color + '-100');
            }
          }
          this.boardData.splice(0, this.boardData.length);
        } else {
          this.boardData = JSON.parse(JSON.stringify(this.boards));
          this.boardGridEdit = true;
        }
      },
      boardGridEditUpdate() {
        if (this.boardData[0].rows.length < 1) {
          this.boardData[0].rows.push(
            {
              title: "",
              order: 1,
              id: uuidv4(),
              color: null,
              freshStart: false,
              cols: [
                {
                  title: "",
                  id: uuidv4(),
                  order: 1,
                  data: [],
                  color: null,
                },
                {
                  title: "",
                  id: uuidv4(),
                  order: 2,
                  data: [],
                  color: null,
                },
                {
                  title: "",
                  id: uuidv4(),
                  order: 3,
                  data: [],
                  color: null,
                },
                {
                  title: "",
                  id: uuidv4(),
                  order: 4,
                  data: [],
                  color: null,
                }
              ]
            });
        }

        this.$store.commit('boardAdd', this.boardData[0]);
        this.boardGridEdit = false;
        this.boardData.splice(0, this.boardData.length);
        if (this.currentUser) {
          this.jobQueueAdd({event: 'boardUpdate', expiration: 500});
        }
      },
      boardAdd(board) {
        this.$store.commit('boardAdd', board);
      },
      boardAddRow() {
        const row = {
          id: uuidv4(),
          title: "",
          order: 0,
          freshStart: false,
          cols: [
            {
              title: "",
              id: uuidv4(),
              order: 1,
              data: [],
              color: null,
            },
            {
              title: "",
              id: uuidv4(),
              order: 2,
              data: [],
              color: null,
            },
            {
              title: "",
              id: uuidv4(),
              order: 3,
              data: [],
              color: null,
            },
            {
              title: "",
              id: uuidv4(),
              order: 4,
              data: [],
              color: null,
            }
          ]
        };
        this.boardData[0].rows.push(row);
      },
      boardRemoveRow(row) {
        let count = 0;
        this.boardData[0].rows[row].cols.forEach((col) => {
          count = count + col.data.length;
        });
        if (count === 0) {
          // this.$store.commit('boardRemoveRow', row);
          this.boardData[0].rows.splice(row, 1);
        } else {
          this.toast.warning(`Please remove any blocks from the columns within the row.`);
        }
      },
      boardAddColumn(row) {
        const column = {
          id: uuidv4(),
          title: "",
          order: 1,
          data: [],
          color: null
        };
        this.boardData[0].rows[row].cols.push(column);

        // this.$store.commit('boardAddColumn', {
        //   row: row,
        //   column: column
        // });
      },
      boardRemoveColumn(row, columnId) {

        let index = this.boardData[0].rows[row].cols.findIndex(item => item.id === columnId );
        this.boardData[0].rows[row].cols.splice(index, 1);

        // this.$store.commit('boardRemoveColumn', {
        //   row: row,
        //   columnId: columnId
        // });
      },
      colorGridSwatchEdit(id) {
        if (this.colorSwatchEdit === id) {
          this.colorSwatchEdit = null;
        } else {
          this.colorSwatchEdit = id;
        }
      },
      colorGridSwatch(element, color) {
        this.colorSwatchEdit = null;
        element.color = color;
      },
      colorBoardSwatch(type, color) {
        this.colorSwatchEdit = null;
        if (type === 'gradient') {
          this.$emit('updateBG', color);
        } else {
          this.$emit('updateBG', 'bg-' + color + '-100');
        }
        this.boardData[0].color = color;
      },
      modeBoardSwatch(mode) {
        this.boardData[0].mode = mode;
        this.modeSwatchEdit = null;
      },
      blockIncrement(blockId) {
        this.$store.commit('blockIncrement', blockId);
        this.calculateTimerTotal();
      },
      blockReset(blockId) {
        this.$store.commit('blockReset', blockId);
      },
      blockAdd(type, id) {
        const color = this.getRandomColor();
        const randomId = uuidv4();
        const block = {
          id: randomId,
          title: 'Block Title',
          type: type,
          color: color,
          size: 2,
          increaseValue: 1,
          faceType: 'default',
          elapsedTime: 0,
          totalTime: 0,
          activeSince: 0,
          activeCountdownSince: 0,
          elements: [],
          isNew: true,
          refID: null
        };

        if (type === 'BlockCountdown') {
          block.size = 120;
        }

        if (type === 'BlockProgress') {
          block.progressStart = new Date().getTime();
          block.progressEnd = 0;
          block.counters = [];
        }

        this.$store.commit('blockAdd', block);

        this.animateButton('[data-im-id="button-' + id + '-add"]', block.id);

        this.blockAddBoard(block);

        if (this.currentUser) {
          createBlock(block)
            .then((res) => {
              this.$store.commit('blockUpdateRef', {id: block.id, refID: res.ref.value.id});
            })
            .catch(() => {
              this.toast.error(`We are not able to create the block.`)
            });
        }
      },
      blockAddBoard(block) {
        const col_data_size = [];
        const columns = document.querySelectorAll("#row-0 > div > div.grid > div");
        columns.forEach((col, colIndex) => {
          let lastBlockSize = col.getBoundingClientRect();
          let lastBlock = 167; // lastBlockSize.top
          let lastBlocks = col.querySelectorAll("[data-draggable='true']");

          let someTop = 0;

          if (lastBlocks.length) {
            lastBlockSize = lastBlocks[lastBlocks.length - 1].getBoundingClientRect();
            lastBlock = lastBlockSize.top + lastBlockSize.height;
            someTop = 10;
          }

          let sizeCenter = 0;

          if (block.type === 'Block') {
            sizeCenter = lastBlock + 120 + someTop;
          } else {
            sizeCenter = lastBlock + 18 + someTop;
          }

          col_data_size.push({
            index: colIndex,
            size: lastBlock,
            sizeCenter: sizeCenter,
            x: lastBlockSize.x,
            y: lastBlockSize.y,
            width: lastBlockSize.width,
          });
        });
        col_data_size.sort((a, b) => a.size - b.size);

        const id = uuidv4();
        this.$store.commit('blockAddBoard', {colIndex: col_data_size[0].index, id: id, blockId: block.id});
        if (this.currentUser) {
          this.jobQueueAdd({event: 'boardUpdate', expiration: 2000});
        }

        this.animeParticles(block, col_data_size[0]);
      },
      blockAddFromTemplate(blockTemplate) {
        const randomId = uuidv4();
        const block = {
          id: randomId,
          title: blockTemplate.title,
          color: blockTemplate.color,
          size: blockTemplate.size,
          faceType: blockTemplate.faceType,
          elapsedTime: 0,
          totalTime: 0,
          activeSince: 0,
          elements: [],
          refID: null
        };

        this.$store.commit('blockAdd', block);
        this.blockAddBoard(block);

        if (this.currentUser) {
          createBlock(block)
              .then((res) => {
                this.$store.commit('blockUpdateRef', {id: block.id, refID: res.ref.value.id});
              })
              .catch(() => {
                this.toast.error(`We are not able to create the block.`)
              });
        }
      },
      blockSaveTemplate(blockTemplate) {
        blockTemplate.id = uuidv4();

        this.$store.commit('blockSaveTemplate', blockTemplate);

        if (this.currentUser) {
          createBlockTemplate(blockTemplate)
            .then((res) => {
              this.$store.commit('blockUpdateTemplateRef', {id: blockTemplate.id, refID: res.ref.value.id});
            })
            .catch(() => {
              this.toast.error(`We are not able to create the template.`)
            });
        }
      },
      blockTemplateDelete(templateId, templateRef) {
        this.$store.commit('blockTemplateDelete', templateId);

        if (this.currentUser) {
          deleteBlockTemplate(templateRef)
            .then(() => {
            })
            .catch(() => {
              this.toast.error(`We were not able to delete the template.`)
            });
        }
      },
      blockDelete(blockId, blockRef) {
        this.soundStop();
        this.blockDeleteBoard(blockId);

        this.$store.commit('blockDelete', blockId);

        this.calculateTimerTotal();

        if (this.currentUser) {
          deleteBlock(blockRef)
          .then(() => {
          })
          .catch(() =>  {
            this.toast.error(`We were not able to delete the block.`)
          });
        }
      },
      blockAdded(blockId) {
        this.$store.commit('blockAdded', blockId);
      },
      blockDeleteBoard(blockId) {
        // find the block in the board
        this.boards[0].rows.forEach((row, rowIndex) => {
          this.boards[0].rows[rowIndex].cols.forEach((col, index) => {
            let blockRef = col.data.findIndex(item => item.block === blockId);
            // found
            if (blockRef > -1) {
              this.$store.commit('blockDeleteBoard', {rowIndex: rowIndex, colIndex: index, blockIndex: blockRef});
              if (this.currentUser) {
                this.jobQueueAdd({event: 'boardUpdate', expiration: 2000});
              }
            }
          })
        });
      },
      blockEditTitle(blockId, title) {
        this.$store.commit('blockEditTitle', {
          id: blockId,
          title: title
        });
      },
      blockEditSize(blockId, size) {
        this.$store.commit('blockEditSize', {
          id: blockId,
          size: size
        });
      },
      blockEditIncreaseValue(blockId, increaseValue) {
        this.$store.commit('blockEditIncreaseValue', {
          id: blockId,
          increaseValue: increaseValue
        });
      },
      blockEditColor(blockId, color) {
        this.$store.commit('blockEditColor', {
          id: blockId,
          color: color
        });
      },
      blockEditProjectRef(blockId, projectId) {
        this.$store.commit('blockEditProjectRef', {
          id: blockId,
          projectRef: projectId
        });
      },
      blockEditSkin(blockId, skin) {
        this.$store.commit('blockEditSkin', {
          id: blockId,
          skin: skin
        });
      },
      blockEditFaceType(blockId, faceType) {
        this.$store.commit('blockEditFaceType', {
          id: blockId,
          faceType: faceType
        });
      },
      blockEditElements(blockId, elements) {
        this.$store.commit('blockEditElements', {
          id: blockId,
          elements: elements
        });
      },
      blockEditCollectors(blockId, collectors) {
        this.$store.commit('blockEditCollectors', {
          id: blockId,
          collectors: collectors
        });
      },
      blockEditTotalTime(blockId, totalTime) {
        this.$store.commit('blockEditTotalTime', {
          id: blockId,
          totalTime: totalTime
        });
      },
      blockEditProgressStart(blockId, progressStart) {
        this.$store.commit('blockEditProgressStart', {
          id: blockId,
          progressStart: progressStart
        });
      },
      blockEditProgressEnd(blockId, progressEnd) {
        this.$store.commit('blockEditProgressEnd', {
          id: blockId,
          progressEnd: progressEnd
        });
      },
      blockEditCounters(blockId, counters) {
        this.$store.commit('blockEditCounters', {
          id: blockId,
          counters: counters
        });
      },
      blockEditEmbedId(blockId, embedId) {
        this.$store.commit('blockEditEmbedId', {
          id: blockId,
          embedId: embedId
        });
      },
      blockEditEmbedOptions(blockId, embedOptions) {
        this.$store.commit('blockEditEmbedOptions', {
          id: blockId,
          embedOptions: embedOptions
        });
      },
      blockEditCancel(blockId, data) {
        if (data.title) this.blockEditTitle(blockId, data.title);
        if (data.size) this.blockEditSize(blockId, data.size);
        if (data.increaseValue) this.blockEditIncreaseValue(blockId, data.increaseValue);
        if (data.color) this.blockEditColor(blockId, data.color);
      },
      blockUpdate(blockRefID, blockId, blockData) {
        if (blockData.title) this.blockEditTitle(blockId, blockData.title);
        if (blockData.size) this.blockEditSize(blockId, blockData.size);
        if (blockData.increaseValue) this.blockEditIncreaseValue(blockId, blockData.increaseValue);
        if (blockData.color) this.blockEditColor(blockId, blockData.color);
        if (typeof blockData.projectRef !== 'undefined') this.blockEditProjectRef(blockId, blockData.projectRef);

        if (typeof blockData.skin !== 'undefined') this.blockEditSkin(blockId, blockData.skin);
        if (typeof blockData.faceType !== 'undefined') this.blockEditFaceType(blockId, blockData.faceType);
        if (typeof blockData.elements !== 'undefined') this.blockEditElements(blockId, blockData.elements);
        if (typeof blockData.collectors !== 'undefined') this.blockEditCollectors(blockId, blockData.collectors);
        if (typeof blockData.totalTime !== 'undefined') this.blockEditTotalTime(blockId, blockData.totalTime);

        if (typeof blockData.progressStart !== 'undefined') this.blockEditProgressStart(blockId, blockData.progressStart);
        if (typeof blockData.progressEnd !== 'undefined') this.blockEditProgressEnd(blockId, blockData.progressEnd);
        if (typeof blockData.counters !== 'undefined') this.blockEditCounters(blockId, blockData.counters);

        if (typeof blockData.embedId !== 'undefined') this.blockEditEmbedId(blockId, blockData.embedId);
        if (typeof blockData.embedOptions !== 'undefined') this.blockEditEmbedOptions(blockId, blockData.embedOptions);

        // Update total time
        this.calculateTimerTotal();

        if (this.currentUser) {
          updateBlock(blockRefID, blockData);
        }
      },
      blockNotificationUpdate(blockId, status) {
        this.$store.commit('blockNotificationUpdate', {
          id: blockId,
          status: status
        });
      },
      blockEditStart(blockId) {
        let index = this.blocks.findIndex(item => item.id === blockId);
        if (typeof this.blocks[index].type === 'undefined') this.blocks[index].type = 'Block';
        this.blockEdited = this.blocks[index];
      },
      blockEditStop() {
        this.blockEdited = {};
      },
      blockAddTime(blockId, time) {
        this.$store.commit('blockAddTime', {
          id: blockId,
          time: time
        });
      },
      blockRemoveTime(blockId, time) {
        this.$store.commit('blockRemoveTime', {
          id: blockId,
          time: time
        });
      },
      getRandomColor() {
        return this.colors[Math.floor(Math.random() * this.colors.length)].value;
      },
      handleTrackingChange (status) {
        // Get or create the favicon
        let color = 'green';
        let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        // Set the attributes of the favicon
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';

        let blockActive = this.blocks.find(block => block.activeSince !== 0 && block.type === 'Block');
        if (blockActive) {
          color = blockActive.color;
        }

        if (status) {
          if (typeof this.favicons[color] === 'undefined') {
            link.href = this.favicons.offline.src;
          } else {
            link.href = this.favicons[color].src;
          }
        } else {
          link.href = this.favicons.offline.src;
        }
        // Append the favicon to the `head`
        document.getElementsByTagName('head')[0].appendChild(link);
      },
      deepWorkStart () {
        let blockActive = this.blocks.find(block => block.activeSince !== 0 && block.type === 'Block');
        if (blockActive) {
          this.elementStart(blockActive.id, 'track_deep');
          this.deepWorkState = 'active';
        }
      },
      deepWorkStop () {
        let blockActive = this.blocks.find(block => block.activeSince !== 0 && block.type === 'Block');
        if (blockActive) {
          this.elementEnd(blockActive.id);
          this.deepWorkState = 'done';
        }
      },
      deepWorkDismiss () {
        this.deepWorkState = false;
        this.deepWorkModal = false;
        this.deepWorkTotal = '00:00:00';
      },
      elementStart (blockId, trackType = 'track') {
        // stop any running block - there should always be just one
        let blockActive = this.blocks.find(block => block.activeSince !== 0 && block.type === 'Block');

        if (blockActive) {
          if (blockActive.id !== blockId) {
            this.elementEnd(blockActive.id);
          } else {
            this.elementEnd(blockId, false);
          }
        }

        let index = this.blocks.findIndex(item => item.id === blockId );
        const randomId = uuidv4();

        const element = {
          id: randomId,
          type: trackType,
          start: new Date().getTime(),
          end: null,
          size: null
        };

        if (trackType === 'track_deep') {
          if (this.deepWorkSize > 0) {
            element.size = this.deepWorkSize;
          }
        }

        const elements = JSON.parse(JSON.stringify(this.blocks[index].elements));

        this.$store.commit('elementStart', {
          index: index,
          element: element
        });

        if (this.currentUser) {
          const blockData = {
            elements: elements,
            activeSince: element.start,
            totalTime: this.blocks[index].totalTime
          };
          blockData.elements.push(element);
          updateBlock(this.blocks[index].refID, blockData)
              .then(() => {
              })
              .catch(() => {
                this.toast.error(`Something went wrong. We were not able to save that into DB.`)
              });
        }

        // Add to collectors
        if (this.blocks[index].collectors && this.blocks[index].collectors.length) {
          this.blocks[index].collectors.forEach((collector) => {
            let blockIndex = this.blocks.findIndex(item => item.id === collector.id);
            if (blockIndex !== -1) {
              const collectorElement = {
                id: element.id,
                start: element.start,
                end: null,
                value: collector.value,
                title: this.blocks[index].title,
                source: 'Block',
              };

              const collectorElements = JSON.parse(JSON.stringify(this.blocks[blockIndex].elements));

              this.$store.commit('elementStart', {
                index: blockIndex,
                element: collectorElement,
              });

              if (this.currentUser) {
                const collectorData = {
                  elements: collectorElements,
                  activeSince: collectorElement.start,
                  totalTime: this.blocks[blockIndex].totalTime
                };
                collectorData.elements.push(collectorElement);
                updateBlock(this.blocks[blockIndex].refID, collectorData)
                    .then(() => {
                    })
                    .catch(() => {
                      this.toast.error(`Something went wrong. We were not able to save that into DB.`)
                    });
              }
            }
          });
        }

        this.handleTrackingChange(true);
      },
      elementEnd (blockId, writeDB = true) {
        let index = this.blocks.findIndex(item => item.id === blockId );
        let n = new Date().getTime();
        let length = this.blocks[index].elements.length - 1;

        const elements = JSON.parse(JSON.stringify(this.blocks[index].elements));

        this.$store.commit('elementEnd', {
          index: index,
          last: length,
          end: n
        });

        if (this.currentUser && writeDB) {
          const blockData = {
            elements: elements,
            activeSince: 0,
            totalTime: 0
          };
          blockData.elements[length].end = n;
          const total = this.calculateElementsTotal(blockData.elements);
          blockData.totalTime = total;

          updateBlock(this.blocks[index].refID, blockData)
          .then(()=>{
          })
          .catch(() => {
            this.toast.error(`Something went wrong. We were not able to save the stop event into DB.`);
          });
        }

        // Add to collectors
        if (this.blocks[index].collectors && this.blocks[index].collectors.length) {
          this.blocks[index].collectors.forEach((collector) => {
            let blockIndex = this.blocks.findIndex(item => item.id === collector.id);
            if (blockIndex !== -1) {
              let collectorLength = this.blocks[blockIndex].elements.length - 1;
              const collectorElements = JSON.parse(JSON.stringify(this.blocks[blockIndex].elements));

              this.$store.commit('elementEnd', {
                index: blockIndex,
                last: collectorLength,
                end: n
              });

              if (this.currentUser && writeDB) {
                const collectorData = {
                  elements: collectorElements,
                  activeSince: 0,
                  totalTime: 0
                };

                collectorData.elements[collectorLength].end = n;
                const total = this.calculateElementsTotal(collectorData.elements);
                collectorData.totalTime = total;

                updateBlock(this.blocks[blockIndex].refID, collectorData)
                  .then(()=>{
                  })
                  .catch(() => {
                    this.toast.error(`Something went wrong. We were not able to save the stop event into DB.`);
                  });
              }

            }
          });
        }
      },
      elementIncrease (blockId, incTitle, incIcon, incValue) {
        let index = this.blocks.findIndex(item => item.id === blockId );
        const randomId = uuidv4();

        const element = {
          id: randomId,
          title: incTitle,
          icon: incIcon,
          start: new Date().getTime(),
          value: incValue
        };

        this.$store.commit('elementUpdate', {
          index: index,
          element: element
        });

        if (this.currentUser) {
          this.jobQueueAdd({event: 'blockUpdate_' + blockId, expiration: 2000});
        }
      },
      elementDecrease (blockId) {
        let index = this.blocks.findIndex(item => item.id === blockId );

        this.$store.commit('elementRemove', {
          index: index
        });

        if (this.currentUser) {
          this.jobQueueAdd({event: 'blockUpdate_' + blockId, expiration: 2000});
        }
      },
      countdownIncrement(blockId) {
        this.$store.commit('countdownIncrement', blockId);
      },
      countdownStart (blockId) {
        let index = this.blocks.findIndex(item => item.id === blockId );
        this.$store.commit('countdownStart', {
          index: index,
          start: new Date().getTime()
        });

        if (this.currentUser) {
          this.jobQueueAdd({event: 'blockUpdate_' + blockId, expiration: 2000});
        }
      },
      countdownEnd (blockId) {
        this.soundStop();
        let index = this.blocks.findIndex(item => item.id === blockId );
        this.$store.commit('countdownEnd', {
          index: index
        });

        if (this.currentUser) {
          this.jobQueueAdd({event: 'blockUpdate_' + blockId, expiration: 2000});
        }
      },
      calculateElementsTotal(elements) {
        let total = 0;
        elements.forEach((element) => {
          total = total + (element.end - element.start);
        });
        return total;
      },
      calculateTimerTotal() {
        let total = 0;
        total = this.blocks.reduce(function (total, block) {
          if (block && block.type === 'Block') {
            let blockTotal = 0;
            if (block.activeSince) {
              const now = new Date().getTime();
              blockTotal = now - block.activeSince;
            }
            blockTotal += Number(block.totalTime);
            blockTotal = blockTotal / 1000;
            blockTotal = ~~blockTotal * 1000;

            return total + blockTotal;
          } else {
            return total;
          }
        }, 0);

        if (total === 0) {
          this.timerTotal = '00:00:00';
        } else {
          const day = 86400 * 1000;
          let days = total / day;
          let daysDisplay = '';
          if (days >= 1) {
            if (days >= 2) {
              daysDisplay = ~~days + ' DAYS ';
            } else {
              daysDisplay = ~~days + ' DAY ';
            }
          }
          const date = new Date(null);
          date.setSeconds(total / 1000);
          const utc = date.toUTCString();
          this.timerTotal = daysDisplay + utc.substr(utc.indexOf(":") - 2, 8);
        }
      },
      calculateTimerSession() {
        let blockActive = this.blocks.find(block => block.activeSince !== 0 && block.type === 'Block');
        let total = 0;
        if (blockActive) {
          const now = new Date().getTime();
          total = now - blockActive.activeSince;
        }

        if (total === 0) {
          this.timerSession = '00:00:00';
        } else {
          const date = new Date(null);
          date.setSeconds(total / 1000);
          const utc = date.toUTCString();
          this.timerSession = utc.substr(utc.indexOf(":") - 2, 8);
        }
      },
      calculateDeepWork() {
        let blockActive = this.blocks.find(block => block.activeSince !== 0 && block.type === 'Block');
        let total = 0;
        if (blockActive) {
          const now = new Date().getTime();
          total = now - blockActive.activeSince;
        }

        if (total === 0) {
          this.deepWorkTotal = '00:00:00';
        } else {
          const date = new Date(null);
          date.setSeconds(total / 1000);
          const utc = date.toUTCString();
          this.deepWorkTotal = utc.substr(utc.indexOf(":") - 2, 8);
        }
      },
      userContent() {
        if (this.currentUser) {
          this.isLoading = true;
          this.resetEverything();
          this.projectsResetAll();

          getBlockTemplates()
              .then(resp => {
                let tplsData = resp.data;
                let blockTpls = [];

                tplsData.forEach(function (block) {
                  block.data.refID = block.ref.value.id;
                  blockTpls.push(block.data);
                });
                this.$store.commit('blockTemplatesAdd', blockTpls);
              })
              .catch(() =>
                  this.toast.error(`We are not able to fetch templates from the database.`)
              );

          getProjects()
              .then(resp => {
                let projectsData = resp.data;
                let projects = [];

                projectsData.forEach(function (project) {
                  project.data.refID = project.ref.value.id;
                  projects.push(project.data);
                });
                this.$store.commit('projectsAdd', projects);
              })
              .catch(() => {
                this.toast.error(`We are not able to fetch projects from the database.`);
              });

          getBlocks()
              .then(resp => {
                let blocksData = resp.data;
                let blocks = [];
                blocksData.forEach(function (block) {
                  block.data.refID = block.ref.value.id;
                  blocks.push(block.data);
                });
                this.$store.commit('blocksAdd', blocks);
                this.getBoardsApp();
              })
              .catch(() =>
                this.toast.error(`We are not able to fetch blocks from the database.`)
              );

        } else {
          if (!this.boards.length) {
            this.boardAddDefault(false);
          }
        }
      },
      getBoardsApp() {
        getBoards()
            .then(resp => {
              let boardsData = resp.data;
              let board = null;
              boardsData.forEach(function (boardData) {
                board = boardData.data;
                board.refID = boardData.ref.value.id;
              });
              if (board === null) {
                // no board found
                // create default and save to db
                this.boardAddDefault(true);
              } else {
                // sanitize board
                board = this.boardSanitize(board);
                this.$store.commit('boardAdd', board);
                this.$emit('updateBG', board.color);
              }
              this.isLoading = false;
            })
            .catch(() =>
                this.toast.error(`We are not able to fetch boards from the database.`)
            );
      },
      boardAddDefault(save = false) {
        let board = {
          title: "Default",
          id: uuidv4(),
          color: '',
          rows: [
            {
              title: "",
              order: 1,
              id: uuidv4(),
              color: null,
              cols: [
                {
                  title: "",
                  id: uuidv4(),
                  order: 1,
                  data: [],
                  color: null,
                },
                {
                  title: "",
                  id: uuidv4(),
                  order: 2,
                  data: [],
                  color: null,
                },
                {
                  title: "",
                  id: uuidv4(),
                  order: 3,
                  data: [],
                  color: null,
                },
                {
                  title: "",
                  id: uuidv4(),
                  order: 4,
                  data: [],
                  color: null,
                }
              ]
            }
          ]
        };

        if (this.blocks.length) {
          // If there are blocks but no board, it means it is an older version
          this.blocks.forEach((block, index) => {
            let pos = index;
            if (index > 3) pos = 1;
            board.rows[0].cols[pos].data.push(
                {
                  id: index,
                  block: block.id
                }
            );
          });
        }

        this.boardAdd(board);
        if (save) {
          createBoard(board)
              .then((res) => {
                this.$store.commit('boardUpdateRef', {refID: res.ref.value.id});
              })
              .catch(() => {
                this.toast.error(`We are not able to create the default board.`)
              });
        }
      },
      deepWork() {
        let blockActive = this.blocks.find(block => block.activeSince !== 0 && block.type === 'Block');
        if (blockActive) {
          if (blockActive.elements[blockActive.elements.length - 1].type === 'track_deep' &&
              (blockActive.elements[blockActive.elements.length - 1].end === null ||
               typeof blockActive.elements[blockActive.elements.length - 1].end === 'undefined')
          ) {
            this.deepWorkState = 'active';
          }
        }
      },
      boardChange() {
        this.$store.commit('boardUpdate', this.boards[0]);
        if (this.currentUser) {
          this.jobQueueAdd({event: 'boardUpdate', expiration: 2000});
        }
      },
      boardSanitize(board) {
        if (board.rows.length) {
          board.rows.forEach((row, rowIndex) => {
            row.cols.forEach((col, colIndex) => {
              col.data.forEach((block, blockIndex) => {
                if (typeof this.blockIndexById(block.block) === "undefined") {
                  board.rows[rowIndex].cols[colIndex].data.splice(blockIndex, 1);
                }
              });
            });
          });
        }
        return board;
      },
      jobQueueAdd(job) {
        /*
        {
          event: 'boardUpdate', // event function to call
          event: 'blockUpdate_1', // parameter passed with underscores
          expiration: 1000 // when to expire in milliseconds
        }
        */
        let index = this.jobQueue.findIndex(item => item.event === job.event);
        if (index >= 0) {
          let timeObject = new Date().getTime();
          const expiration = timeObject + job.expiration;
          this.jobQueue[index].expiration = expiration;
        } else {
          let timeObject = new Date().getTime();
          const expiration = timeObject + job.expiration;
          this.jobQueue.push({id: uuidv4(), event: job.event, expiration: expiration});
        }
      },
      jobQueueProcess() {
        // execute only if jobQueue is not running
        if (!this.jobQueueRunning) {
          this.jobQueueRunning = true;
          const jobsToRun = JSON.parse(JSON.stringify(this.jobQueue));
          //this.jobQueue.splice(0, this.jobQueue.length); // clear the queue so other events can be added and processed later

          jobsToRun.forEach((job) => {
            let timeNow = new Date().getTime();
            if (job.expiration < timeNow) {
              let jobEvent = job.event.split("_");
              if (jobEvent[0] === "boardUpdate") {
                let job_index = this.jobQueue.findIndex(item => item.id === job.id);
                this.jobQueue.splice(job_index, 1);
                delete this.boards[0].owner;
                updateBoard(this.boards[0].refID, this.boards[0])
                    .then(() => {
                    })
                    .catch(() => {
                      this.toast.error(`Something went wrong. We were not able to save the Board updates into DB.`)
                    });
              } else if (jobEvent[0] === "blockUpdate") {
                let job_index = this.jobQueue.findIndex(item => item.id === job.id);
                this.jobQueue.splice(job_index, 1);
                let block_index = this.blocks.findIndex(item => item.id === jobEvent[1]);
                if (block_index >= 0) {
                  const blockData = this.blocks[block_index];
                  updateBlock(blockData.refID, blockData)
                      .then(() => {
                      })
                      .catch(() => {
                        this.toast.error(`Something went wrong. We were not able to save that into DB.`)
                      });
                }
              }
            }
          });

          this.jobQueueRunning = false;
        }
      },
      dataPopulate() {
        this.$store.commit('blocksAdd', this.presets[0].blocks);
        const board = this.boardSanitize(this.presets[0].boards[0]);
        this.boardAdd(board);
      },
      encode(data) {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
            .join('&')
      },
      handleSubmit(e) {
        fetch('/', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: this.encode({
            'form-name': e.target.getAttribute('name'),
            ...this.formData,
          }),
        })
            .then(() => this.toast.success(`Thank you for being part of our journey! We will be in touch.`))
            .catch(() => this.toast.error(`Something went wrong. Please try again. :(`))
      },
      setTrackerHeight() {
        let navHeight = document.getElementById("app-nav").clientHeight;
        let trackerNavHeight = document.getElementById("tracker-nav").clientHeight;
        let viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
        let trackerHeight = viewportHeight - (navHeight + trackerNavHeight);
        document.getElementById("tracker-main").style.height = trackerHeight + 'px';
      },
      soundPlay() {
        this.audio = new Audio(sfx);
        this.audio.loop = true;
        this.audio.play();
      },
      soundStop() {
        if (this.audio !== null) {
          this.audio.pause();
          this.audio = null;
        }
      },
      demoChangeColor() {
        // find block
        // change color
        let blockActive = this.blocks.find(block => block.activeSince !== 0 && block.type === 'Block');
        this.demoColor = this.demoColor + 1;
        let skinIndex = Math.floor(Math.random() * 4);
        blockActive.skin = this.skins[skinIndex].value;
        blockActive.color = this.colors[this.demoColor].value;
      },
      animateButton(id) {
        nextTick(() => {
          anime({
            targets: id,
            easing: 'easeOutQuad',
            duration: 175,
            scale: [{value: 0.9},{value: 1}],
            //paddingBottom: [30],
            //marginTop: [20, 0],
            backgroundColor: ['#6EE7B7', '#bbf7d0'],
            borderBottom: [1, 3],
            boxShadow: ['inset 0px 0px 0px #34D399, inset 0 0px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5', 'inset 0px -6px 0px #34D399, inset 0 -7px 0 0 #ECFDF5, inset 0px 1px 0px 1px #ECFDF5'],
            complete: () => {
              anime.remove(id);
            }
          });
          anime({
            targets: id + ' svg',
            easing: 'easeOutQuad',
            duration: 175,
            //scale: [{value: 0.95},{value: 1}],
            paddingBottom: [0, 0],
            paddingTop: [4, 0],
            complete: () => {
              anime.remove(id);
            }
          });
        });
      },
      bezier(t, p0, p1, p2, p3) {
        var cX = 3 * (p1.x - p0.x),
            bX = 3 * (p2.x - p1.x) - cX,
            aX = p3.x - p0.x - cX - bX;

        var cY = 3 * (p1.y - p0.y),
            bY = 3 * (p2.y - p1.y) - cY,
            aY = p3.y - p0.y - cY - bY;

        var x = (aX * Math.pow(t, 3)) + (bX * Math.pow(t, 2)) + (cX * t) + p0.x;
        var y = (aY * Math.pow(t, 3)) + (bY * Math.pow(t, 2)) + (cY * t) + p0.y;

        return {x: x, y: y};
      },
      animeParticles(block, endEl) {
        const startEl = 'button[data-im-id="button-' + block.type.toLowerCase() + '-add"]';
        const startElement = document.querySelector(startEl).getBoundingClientRect();
        const endX = ((endEl.width/2) - 30) + endEl.x;

        const animeContainers = document.querySelectorAll('.anime-container');

        setTimeout(() => {
          animeContainers.forEach((el) => {
            el.style.transform='translateX('+ (startElement.x + 1) +'px) translateY('+ (startElement.y - 6) +'px)';
            el.style.opacity = 0.7;
            el.style.display = 'block';
            el.style.width = 40 + 'px';
            el.style.height = 40 + 'px';
            el.style.borderWidth = 1 + 'px';
          });

          let accuracy = 0.01; //this'll give the bezier 100 segments
          let p0 = {x: startElement.x + 1, y: startElement.y - 6};
          let p1 = {x: startElement.x + 60, y: startElement.y - 270};
          let p2 = {x: endX - 200, y: endEl.sizeCenter + 150};
          let p3 = {x: endX, y: endEl.sizeCenter};

          const pointsX = [];
          const pointsY = [];

          for (var i=0; i<1; i+=accuracy) {
            var p = this.bezier(i, p0, p1, p2, p3);
            pointsX.push(p.x);
            pointsY.push(p.y);
          }

          let blockHeight  = 60;
          let blockWidth  = 60;

          if (block.type === 'Block') {
            blockHeight  = 70;
            blockWidth  = 70;
          }
          let colorIndex = this.colors.findIndex(item => item.value === block.color );

          anime({
            loop: false,
            easing: 'easeInSine',
            duration: 398,
            targets: '.anime-container',
            translateX: pointsX,
            translateY: pointsY,
            opacity: anime.stagger([0.2, 1]),
            //scale: anime.stagger([1, 2.3]),
            width: [44, blockWidth],
            height: [44, blockHeight],
            rotate: [180, 3],
            delay: anime.stagger(10, {direction: 'reverse'}),
            backgroundColor: ['#6EE7B7', '#' + this.colors[colorIndex].colors[200]],
            borderColor: ['#16A34A', '#' + this.colors[colorIndex].colors[700]],
            borderWidth: [1, 2, 2, 2.5, 2.7],
            complete: () => {
              animeContainers.forEach((el) => {
                el.style.opacity=0;
                el.style.display='none';
              });
            }
          });
        }, 200);
      },
      // el = element to animate
      // start El = starting element - from
      // end El = ending element - to
      animeFly(startEl, endEl, el) {
        const startElement = document.querySelector(startEl).getBoundingClientRect();
        const endElement = document.querySelector(endEl).getBoundingClientRect();
        const startX = ((startElement.width/2) - 20) + startElement.x;
        const startY = ((startElement.height/2) - 20) + startElement.y;
        const endX = ((endElement.width/2) - 20) + endElement.x;
        const endY = ((endElement.height/2) - 20) + endElement.y;

        const animeEl = document.querySelectorAll(el);

        setTimeout(() => {
          animeEl.forEach((el) => {
            el.style.transform='translateX('+ (startX) +'px) translateY('+ (startY) +'px)';
            el.style.opacity = 0.7;
            el.style.display = 'block';
            el.style.width = 40 + 'px';
            el.style.height = 40 + 'px';
            el.style.borderWidth = 1 + 'px';
          });

          let accuracy = 0.01; //this'll give the bezier 100 segments
          let p0 = {x: startX, y: startY - 6};
          let p1 = {x: startX + 20, y: startY - 370};
          let p2 = {x: endX - 500, y: endY};
          let p3 = {x: endX, y: endY};

          const pointsX = [];
          const pointsY = [];

          for (var i=0; i<1; i+=accuracy) {
            var p = this.bezier(i, p0, p1, p2, p3);
            pointsX.push(p.x);
            pointsY.push(p.y);
          }


          //let colorIndex = this.colors.findIndex(item => item.value === color );

          anime({
            loop: false,
            easing: 'easeInSine',
            duration: 800,
            targets: el,
            translateX: pointsX,
            translateY: pointsY,
            opacity: anime.stagger([0.3, 1]),
            scale: [0.5, 1],
            //width: [30, 30],
            //height: [30, 30],
            rotate: [180, 360],
            delay: anime.stagger(10, {direction: 'reverse'}),
            //backgroundColor: ['#6EE7B7', '#' + this.colors[colorIndex].colors[200]],
            //borderColor: ['#16A34A', '#' + this.colors[colorIndex].colors[700]],
            //borderWidth: [1, 2, 2, 2.5, 2.7],
            complete: () => {
              animeEl.forEach((el) => {
                //el.style.opacity=0;
                el.style.display='none';
              });
            }
          });

          let svgEl = document.querySelector(endEl + ' svg');
          svgEl.style.transform = 'matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)';

          anime({
            targets: endEl + ' svg',
            easing: 'linear',
            duration: 420,
            opacity: [1, 1],
            delay: 800,
            matrix3d: [
              '0.1, 0, 0, 0, 0, 0.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '0.318, 0, 0, 0, 0, 0.318, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '0.448, 0, 0, 0, 0, 0.448, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '0.688, 0, 0, 0, 0, 0.688, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '0.983, 0, 0, 0, 0, 0.983, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '1.011, 0, 0, 0, 0, 1.011, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '1.131, 0, 0, 0, 0, 1.131, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '1.147, 0, 0, 0, 0, 1.147, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '1.056, 0, 0, 0, 0, 1.056, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '1.129, 0, 0, 0, 0, 1.129, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '1.097, 0, 0, 0, 0, 1.097, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '1.023, 0, 0, 0, 0, 1.023, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '0.996, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '0.985, 0, 0, 0, 0, 0.985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '0.965, 0, 0, 0, 0, 0.965, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '0.967, 0, 0, 0, 0, 0.967, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '0.988, 0, 0, 0, 0, 0.988, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '0.996, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1',
              '1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1'
            ],
            complete: () => {
              anime.remove(endEl + ' svg');
            }
          });

        }, 0);
      },
    },
    created () {
      // Create the JS images for on/off favicons
      this.favicons = {
        'offline': new Image(),
        'bluegray': new Image(),
        'gray': new Image(),
        'truegray': new Image(),
        'warmgray': new Image(),
        'red': new Image(),
        'orange': new Image(),
        'amber': new Image(),
        'yellow': new Image(),
        'lime': new Image(),
        'green': new Image(),
        'emerald': new Image(),
        'teal': new Image(),
        'cyan': new Image(),
        'lightblue': new Image(),
        'blue': new Image(),
        'indigo': new Image(),
        'violet': new Image(),
        'purple': new Image(),
        'fuchsia': new Image(),
        'pink': new Image(),
        'rose': new Image()
      };

      // Set the source properties
      this.favicons.offline.src = require('@/assets/favicon/favicon-off.png');
      this.favicons.bluegray.src = require('@/assets/favicon/favicon-bluegray.png');
      this.favicons.gray.src = require('@/assets/favicon/favicon-gray.png');
      this.favicons.truegray.src = require('@/assets/favicon/favicon-truegray.png');
      this.favicons.warmgray.src = require('@/assets/favicon/favicon-warmgray.png');
      this.favicons.red.src = require('@/assets/favicon/favicon-red.png');
      this.favicons.orange.src = require('@/assets/favicon/favicon-orange.png');
      this.favicons.amber.src = require('@/assets/favicon/favicon-amber.png');
      this.favicons.yellow.src = require('@/assets/favicon/favicon-yellow.png');
      this.favicons.lime.src = require('@/assets/favicon/favicon-lime.png');
      this.favicons.green.src = require('@/assets/favicon/favicon-green.png');
      this.favicons.emerald.src = require('@/assets/favicon/favicon-emerald.png');
      this.favicons.teal.src = require('@/assets/favicon/favicon-teal.png');
      this.favicons.cyan.src = require('@/assets/favicon/favicon-cyan.png');
      this.favicons.lightblue.src = require('@/assets/favicon/favicon-lightblue.png');
      this.favicons.blue.src = require('@/assets/favicon/favicon-blue.png');
      this.favicons.indigo.src = require('@/assets/favicon/favicon-indigo.png');
      this.favicons.violet.src = require('@/assets/favicon/favicon-violet.png');
      this.favicons.purple.src = require('@/assets/favicon/favicon-purple.png');
      this.favicons.fuchsia.src = require('@/assets/favicon/favicon-fuchsia.png');
      this.favicons.pink.src = require('@/assets/favicon/favicon-pink.png');
      this.favicons.rose.src = require('@/assets/favicon/favicon-rose.png');
      this.handleTrackingChange(false);

      animationInterval(1000, this.controller.signal, () => {
        let blockActive = this.blocks.find(block => block.activeSince !== 0 && block.type === 'Block');
        if (blockActive) {
          if (blockActive.elements[blockActive.elements.length - 1].type === 'track_deep' &&
              (blockActive.elements[blockActive.elements.length - 1].end === null ||
              typeof blockActive.elements[blockActive.elements.length - 1].end === 'undefined')
          ) {
            this.calculateDeepWork();
          } else {
            // collector push to the collector from active block
            let seconds = Math.floor(((new Date().getTime() + 1000) / 1000) % 60);
            if (seconds % 5 === 0) {
              if (blockActive.collectors && blockActive.collectors.length) {
                // emit collector
                blockActive.collectors.forEach((collector) => {
                  let blockIndex = this.blocks.findIndex(item => item.id === collector.id);
                  if (blockIndex !== -1) {
                    // animate
                    let startEl = '#block-' + blockActive.id;
                    let endEl = '#block-' + this.blocks[blockIndex].id + ' .block-icon';
                    let el = '#block-' + blockActive.id + '-collector-' + collector.id;
                    this.animeFly(startEl, endEl, el);
                  }
                });
              }
            }
          }
          this.calculateTimerSession();
          this.blockIncrement(blockActive.id);
        }
        const blockCountdownActive = this.blocks.filter(block => block.activeCountdownSince > 0);
        if (blockCountdownActive.length > 0) {
          blockCountdownActive.forEach((block) => {
            this.countdownIncrement(block.id);
          });
        }
        let theUpdateTime = new Date().getTime() + 1000;
        if (Math.floor((theUpdateTime / 1000) % 60) % 5 === 0) {
          setTimeout(() => {
            this.blockTick = theUpdateTime;
          }, 900);
        }
      });
    },
    mounted () {
      this.calculateTimerTotal();
      this.calculateTimerSession();

      this.userContent();

      this.deepWork();
      this.jobQueueInterval = setInterval(() => {
        this.jobQueueProcess();
      }, 1000);
      // set background
      if(this.boards.length) {
        if (typeof this.boards[0].color !== "undefined") {
          if (this.boards[0].color.indexOf("bg-") >= 0) {
            this.$emit('updateBG', this.boards[0].color);
          } else {
            this.$emit('updateBG', 'bg-' + this.boards[0].color + '-100');
          }
        }
      }
      // set height

      window.addEventListener("resize", this.setTrackerHeight);

      // Worker
      if (window.Worker) {
        this.worker = new Worker();
        this.worker.postMessage('');
        this.worker.onmessage = () => {
          const blockCountdownActive = this.blocks.filter(block => block.activeCountdownSince > 0);
          if (blockCountdownActive.length > 0) {
            blockCountdownActive.forEach((block) => {
              if (!block.notificationSent) {
                let fullTime = block.size;
                const now = new Date().getTime();
                const elapsed = (now - block.activeCountdownSince) / 1000;
                fullTime = fullTime - elapsed;
                if (fullTime < 0) {
                  if (this.audio === null) {
                    this.soundPlay();
                  }
                  this.blockNotificationUpdate(block.id, true);
                  this.nativeNotification.show(
                      'Kairo: Time is up',
                      {
                        body: block.title + ' has finished.',
                        requireInteraction: true
                      },
                      {},
                  );
                }
              }
            });
          }
        };
      }

      nextTick(() => {
        this.setTrackerHeight();
      });
    },
    unmounted() {
      this.$emit('updateBG', 'none');
      this.controller.abort();
      this.worker.terminate();
      window.removeEventListener("resize", this.setTrackerHeight);
    },
    beforeUnmount () {
      clearInterval(this.jobQueueInterval);
    },
  }
</script>
<style>
  .custom-outline {
    box-shadow: 0 0 1px 1px rgba(100,100,100,0.2);
    background-color: rgba(100,100,100,0.1);
  }
  .col-transition {
    transition-property: background-color, box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;
  }
  .block-drag-class .block-component {
    height: 90px !important;
    overflow: hidden;
  }
  .block-drag-class .block-component canvas,
  .block-drag-class .block-component [id^='block-face-'] svg {
    display: none;
  }
  .bounce-enter-active {
    animation: bounceme .6s linear both;
  }
  .bounce-leave-active {
    animation: bounce-in .2s reverse;
  }
  .bounce-add {
    animation: bounceme .7s linear .4s both;
  }

  @keyframes bounceopacity {
    0% {
      display: none;
      opacity: 0;
    }
    1% {
      display: block;
      opacity: 0;
    }
    100% {
      display: block;
      opacity: 1;
    }
  }

  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }

  .bg-grad-1 {
    background-color: #4158D0;
    background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
  }
  .bg-grad-2 {
    background-color: #0093E9;
    background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
  }
  .bg-grad-3 {
    background-color: #74EBD5;
    background-image: linear-gradient(90deg, #74EBD5 0%, #9FACE6 100%);
  }
  .bg-grad-4 {
    background-color: #FBAB7E;
    background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%);
  }
  .bg-grad-5 {
    background-color: #85FFBD;
    background-image: linear-gradient(45deg, #85FFBD 0%, #FFFB7D 100%);
  }
  .bg-grad-6 {
    background-color: #8BC6EC;
    background-image: linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%);
  }
  .bg-grad-7 {
    background-color: #A9C9FF;
    background-image: linear-gradient(180deg, #A9C9FF 0%, #FFBBEC 100%);
  }
  .bg-grad-8 {
    background-color: #FF3CAC;
    background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);
  }


  /* Generated with Bounce.js. Edit at http://bouncejs.com#%7Bs%3A%5B%7BT%3A%22c%22%2Ce%3A%22b%22%2Cd%3A500%2CD%3A0%2Cf%3A%7Bx%3A0.2%2Cy%3A0.2%7D%2Ct%3A%7Bx%3A1%2Cy%3A1%7D%2Cs%3A1%2Cb%3A4%7D%2C%7BT%3A%22c%22%2Ce%3A%22b%22%2Cd%3A500%2CD%3A0%2Cf%3A%7Bx%3A0.5%2Cy%3A0.5%7D%2Ct%3A%7Bx%3A1%2Cy%3A1%7D%2Cs%3A1%2Cb%3A6%7D%5D%7D */
  @keyframes bounceme {
    0% { -webkit-transform: matrix3d(0.1, 0, 0, 0, 0, 0.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.1, 0, 0, 0, 0, 0.1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);opacity: 0;}
    3.4% { -webkit-transform: matrix3d(0.318, 0, 0, 0, 0, 0.318, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.318, 0, 0, 0, 0, 0.318, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);opacity: 0.9; }
    4.7% { -webkit-transform: matrix3d(0.448, 0, 0, 0, 0, 0.448, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.448, 0, 0, 0, 0, 0.448, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    6.81% { -webkit-transform: matrix3d(0.688, 0, 0, 0, 0, 0.688, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.688, 0, 0, 0, 0, 0.688, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    9.41% { -webkit-transform: matrix3d(0.983, 0, 0, 0, 0, 0.983, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.983, 0, 0, 0, 0, 0.983, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    10.21% { -webkit-transform: matrix3d(1.061, 0, 0, 0, 0, 1.061, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.061, 0, 0, 0, 0, 1.061, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    13.61% { -webkit-transform: matrix3d(1.281, 0, 0, 0, 0, 1.281, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.281, 0, 0, 0, 0, 1.281, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    14.11% { -webkit-transform: matrix3d(1.297, 0, 0, 0, 0, 1.297, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.297, 0, 0, 0, 0, 1.297, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    17.52% { -webkit-transform: matrix3d(1.306, 0, 0, 0, 0, 1.306, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.306, 0, 0, 0, 0, 1.306, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    18.72% { -webkit-transform: matrix3d(1.279, 0, 0, 0, 0, 1.279, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.279, 0, 0, 0, 0, 1.279, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    21.32% { -webkit-transform: matrix3d(1.197, 0, 0, 0, 0, 1.197, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.197, 0, 0, 0, 0, 1.197, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    24.32% { -webkit-transform: matrix3d(1.098, 0, 0, 0, 0, 1.098, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.098, 0, 0, 0, 0, 1.098, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    25.23% { -webkit-transform: matrix3d(1.073, 0, 0, 0, 0, 1.073, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.073, 0, 0, 0, 0, 1.073, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    29.03% { -webkit-transform: matrix3d(0.996, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.996, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    29.93% { -webkit-transform: matrix3d(0.985, 0, 0, 0, 0, 0.985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.985, 0, 0, 0, 0, 0.985, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    35.54% { -webkit-transform: matrix3d(0.965, 0, 0, 0, 0, 0.965, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.965, 0, 0, 0, 0, 0.965, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    36.74% { -webkit-transform: matrix3d(0.967, 0, 0, 0, 0, 0.967, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.967, 0, 0, 0, 0, 0.967, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    41.04% { -webkit-transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    44.44% { -webkit-transform: matrix3d(0.988, 0, 0, 0, 0, 0.988, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.988, 0, 0, 0, 0, 0.988, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    52.15% { -webkit-transform: matrix3d(0.996, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.996, 0, 0, 0, 0, 0.996, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    59.86% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    63.26% { -webkit-transform: matrix3d(1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    75.28% { -webkit-transform: matrix3d(1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1.002, 0, 0, 0, 0, 1.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    85.49% { -webkit-transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    90.69% { -webkit-transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
    100% { -webkit-transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);opacity: 1; }
  }

  .anime-container {
    top: 0;
    left: 0;
    z-index: 100;
  }
  .collector-anim {
    top: 0;
    left: 0;
    z-index: 39;
  }

</style>
