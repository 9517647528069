<template>
  <div class="min-h-screen bg-white"
    :class="bodyClass"
  >
    <nav id="app-nav" class="bg-white shadow sticky top-0 z-40">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex justify-between h-16">
          <div class="flex">
            <div class="-ml-2 mr-2 flex items-center md:hidden">
              <!-- Mobile menu button -->
              <button @click="mainMenuToggle = !mainMenuToggle" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500" aria-expanded="false">
                <span class="sr-only">Open main menu</span>
                <svg v-if="mainMenuToggle" class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
                <svg v-else class="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
              </button>
            </div>
            <div class="flex-shrink-0 flex items-center">
              <router-link
                to="/"
                style="background-color: #fff !important;"
              >
                <img class="cursor-pointer block lg:block h-8 w-auto" src="@/assets/logo.png"/>
              </router-link>
            </div>

            <div class="hidden md:ml-6 md:flex md:items-center md:space-x-4">
              <!-- Current: "border-green-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" -->
              <router-link
                :to="{ path: '/', hash: '#features' }"
                class="text-gray-500 hover:bg-gray-50 hover:text-gray-500 px-3 py-2 rounded-md text-sm font-medium"
              >Features</router-link>
              <router-link
                to="/pricing"
                class="text-gray-500 hover:bg-gray-50 hover:text-gray-500 px-3 py-2 rounded-md text-sm font-medium"
              >Pricing</router-link>
              <router-link
                to="/notion-blocks"
                class="text-gray-500 relative hover:bg-gray-50 hover:text-gray-500 px-3 py-2 rounded-md text-sm font-medium"
              >
                Notion blocks</router-link>
            </div>
          </div>
          <div class="flex items-center">
            <div v-if="currentUser" class="flex-shrink-0">
              <div v-if="currentUser.status === 'trialing'">
                <router-link to="/subscription" type="button" class="inline-flex items-center px-3 mr-2 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-yellow-700 bg-yellow-100 hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
                  {{currentUser.trial_end}}.
                </router-link>
              </div>
              <div v-if="currentUser.status === 'trial_expired'">
                <router-link to="/subscription" type="button" class="inline-flex items-center px-3 mr-2 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                  Trial expired. Subscribe now.
                </router-link>
              </div>
            </div>
            <a class="mr-1 inline-flex items-center px-1 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-red-500 bg-transparent hover:text-red-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
               href="https://www.youtube.com/watch?v=fuV8SMyMsIg"
               target="_blank"
            >
              <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd"></path></svg>
              Watch video
            </a>
            <button v-if="currentUser" @click="IMTopicActivate(90529)" id="appFeedback" type="button" class="mr-1 inline-flex items-center px-1 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 bg-transparent hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
              <svg class="-ml-0.5 mr-1 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z" clip-rule="evenodd"></path></svg>
              Feedback?
            </button>
            <button v-if="currentUser" @click="IMWidgetActivate" id="appHelp" type="button" class="inline-flex items-center px-1 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-500 bg-transparent hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
              <svg class="-ml-0.5 mr-1 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd"></path></svg>
              Help
            </button>
            <div v-if="currentUser" class="flex-shrink-0 border-l-2 ml-3">
              <!-- Profile dropdown -->
              <div class="ml-3 relative">
                <div>
                  <button
                    @click="profileToggle = !profileToggle"
                    class="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500" id="user-menu" aria-haspopup="true">
                    <span class="sr-only">Open user menu</span>
                    <img class="h-8 w-8 rounded-full" src="@/assets/avatar.png"/>
                  </button>
                </div>
                <div v-if="profileToggle" class="z-20 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
                  <router-link
                    @click="profileToggle = false"
                    to="/profile"
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem"
                  >Profile</router-link>
                  <router-link
                    @click="profileToggle = false"
                    to="/profile-edit"
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem"
                  >Settings</router-link>
                  <router-link
                    @click="profileToggle = false"
                    to="/subscription"
                    class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem"
                  >Subscription</router-link>
                  <a @click="logout" class="cursor-pointer block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" role="menuitem">Log out</a>
                </div>
              </div>

            </div>
            <div v-if="!currentUser" class="flex-shrink-0 border-l-2 ml-3">
              <a
                href="https://app.getkairo.com/signup"
                class="ml-2 inline-block rounded-lg font-medium leading-none py-2 px-3 focus:outline-none bg-green-200 text-green-900 hover:text-green-600 focus:text-green-600"
              >Start trial
              </a>
            </div>
            <div v-if="!currentUser" class="flex-shrink-0 border-l-2 ml-3">
              <a
                href="https://app.getkairo.com"
                class="ml-2 inline-block rounded-lg font-medium leading-none py-2 px-3 focus:outline-none text-gray-500 hover:text-green-600 focus:text-green-600"
              >Log in
              </a>
            </div>
          </div>
        </div>
      </div>

      <!--
        Mobile menu, toggle classes based on menu state.

        Menu open: "block", Menu closed: "hidden"
      -->
      <div v-if="mainMenuToggle" class="md:hidden absolute w-full bg-white shadow">
        <div class="pt-2 pb-3 space-y-1">
          <!-- Current: "bg-green-50 border-green-500 text-green-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" -->
          <a
            @click="navChange('Home')"
            class="cursor-pointer border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
          >Home</a>
          <a
            @click="navChange('Pricing')"
            class="cursor-pointer border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
          >Pricing</a>
          <a
            @click="navChange('Notion blocks')"
            class="cursor-pointer border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium sm:pl-5 sm:pr-6"
          >Notion blocks</a>
        </div>
      </div>
    </nav>
    <router-view
      @updateBG="updateBG"
    />
  </div>
</template>

<script>

  import {mapGetters, mapActions} from "vuex";
  import Plausible from 'plausible-tracker';
  import {v4 as uuidv4} from "uuid";
  import dayjs from "dayjs";

  const { enableAutoPageviews } = Plausible({
    domain: 'getkairo.com',
    hashMode: true
  });
  enableAutoPageviews();

  export default {
    data() {
      return {
        profileToggle: false,
        mainMenuToggle: false,
        bodyClass: '',
        freeAvailable: true
      }
    },
    computed: {
      ...mapGetters("auth", ["currentUser"]),
    },
    methods: {
      ...mapActions("auth", [
        "attemptLogout",
      ]),
      ...mapActions(["resetEverything", "projectsResetAll"]),
      logout() {
        this.profileToggle = false;
        this.attemptLogout()
            .then(resp => {
              this.resetEverything();
              this.projectsResetAll();

              if (typeof window.InlineManual !== 'undefined') {
                window.InlineManual("shutdown");
                window.InlineManual("boot", {
                  uid: 'anon-' + uuidv4()
                });
              }

              this.$router.push({name: "Home"});
              console.log("logged out", resp);
            })
            .catch(error => {
              location.reload();
              console.error("Problem with logout", error);
            });
      },
      navChange(path) {
        this.mainMenuToggle = false;
        this.$router.push({name: path});
      },
      updateBG(className) {
        this.bodyClass = className;
      },
      IMTopicActivate(topicId) {
        if (window.inline_manual_player.getCurrentTopic() === "90529") {
          window.inline_manual_player.deactivate();
        } else {
          window.inline_manual_player.activateTopic(topicId);
        }
      },
      IMWidgetActivate() {
        window.inline_manual_player.togglePanel();
      }
    },
    mounted() {
      if (typeof window.InlineManual !== 'undefined') {
        if (this.currentUser) {
          window.InlineManual("boot", {
            uid: this.currentUser.id,
            plan: this.currentUser.app_plan,
            name: this.currentUser.user_metadata.full_name,
            created: dayjs(this.currentUser.created_at).unix()
          });
        } else {
          window.InlineManual("boot", {
            uid: 'anon-' + uuidv4()
          });
        }
      }
    },
  };
</script>
<style>
  .bg-grad-1 {
    background-color: #4158D0;
    background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
  }
  .bg-grad-2 {
    background-color: #0093E9;
    background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
  }
  .bg-grad-3 {
    background-color: #74EBD5;
    background-image: linear-gradient(90deg, #74EBD5 0%, #9FACE6 100%);
  }
  .bg-grad-4 {
    background-color: #FBAB7E;
    background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%);
  }
  .bg-grad-5 {
    background-color: #85FFBD;
    background-image: linear-gradient(45deg, #85FFBD 0%, #FFFB7D 100%);
  }
  .bg-grad-6 {
    background-color: #8BC6EC;
    background-image: linear-gradient(135deg, #8BC6EC 0%, #9599E2 100%);
  }
  .bg-grad-7 {
    background-color: #A9C9FF;
    background-image: linear-gradient(180deg, #A9C9FF 0%, #FFBBEC 100%);
  }
  .bg-grad-8 {
    background-color: #FF3CAC;
    background-image: linear-gradient(225deg, #FF3CAC 0%, #784BA0 50%, #2B86C5 100%);
  }
</style>
