/*
Extract and validate tokens in the URL if they are present.
*/
import store from "../store/store";
import router from "../router";
import { useToast } from 'vue-toastification'

const toast = useToast();

/**
 * Reads the URL hash attempts and tries to detect if there is confirmation tokens from an email signup.
 * If present it will call the relevant process to attempt to authorise the token.
 */
function detectTokens() {
  const emailToken = detectEmailConfirmationToken();
  const recoveryToken = detectRecoveryToken();

  if (emailToken) {
    confirmEmailToken(emailToken);
    return;
  } else if (recoveryToken) {
    confirmRecoveryToken(recoveryToken);
    return;
  }
}

/**
 * Checks URL hash for `confirmation_token=` then extracts the token which proceeds.
 */
function detectEmailConfirmationToken() {
  try {
    // split the hash where it detects `confirmation_token=`. The string which proceeds is the part which we want.
    const token = decodeURIComponent(document.location.hash).split(
        "confirmation_token="
    )[1];
    return token;
  } catch (error) {
    console.error(
        "Something went wrong when trying to extract email confirmation email.",
        error
    );
    return null;
  }
}

function detectRecoveryToken() {
  try {
    // split the hash where it detects `confirmation_token=`. The string which proceeds is the part which we want.
    const token = decodeURIComponent(document.location.hash).split(
        "recovery_token="
    )[1];
    return token;
  } catch (error) {
    console.error(
        "Something went wrong when trying to extract email confirmation email",
        error
    );
    return null;
  }
}

/**
 * @param {string} token - authentication token used to confirm a user who has created an account via email signup.
 */
function confirmEmailToken(token) {
  store
    .dispatch("auth/attemptConfirmation", token)
    .then(resp => {
      toast.success(`${resp.email} has been confirmed, please Log in.`);
    })
    .catch(() => {
      toast.error(`Can't authorise your account right now. Please try again.`);
    });
}

function confirmRecoveryToken(recoveryToken) {
  store
      .dispatch("auth/attemptPasswordRecovery", recoveryToken)
      .then(() => {
        router.push("profile-edit");
        toast.success(`Account has been recovered. Update your password.`);
      })
      .catch(() => {
        toast.error(`Can't recover password.`);
      });
}

export default function() {
  detectTokens();
}
